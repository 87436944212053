import * as React from 'react';
import OfficeAddress from 'components/office-list/OfficeAddress';
import OfficeContacts from 'components/office-list/OfficeContacts';
import OfficeExportButton from 'components/office-list/OfficeExportButton';
import OfficeRoutes from 'components/office-list/OfficeRoutes';
import OfficeWorkTime from 'components/office-list/OfficeWorkTime';
import { getEcontItemName, ICity, IOffice, officeTypeEnumToString } from 'models';
import { IMapActions, MapActions } from 'store/slices/map';
import { IMapsService } from 'services/contracts';
import { IApplicationState } from 'store/contracts';
import { connect } from 'react-redux';
import { getMapsService } from 'services';
import { ILocation, IOfficeLocatorInput, IOfficeLocatorOutput } from 'contracts';

import './ListItemContainer.scss';

interface IOwnProps {
    office: IOffice;
    isSelected?: boolean;
    isLastSelectedOffice?: boolean
    onClick?: (office: IOffice) => void;
}

interface IStateProps {
    mapsService: IMapsService;
    mapLocation?: ILocation;
    queryParams: IOfficeLocatorInput;
    lang: string;
    exportOffice?: (output: IOfficeLocatorOutput) => void;
    selectedCity?: ICity;
}

type PropsType = IOwnProps & IStateProps & IMapActions;

const Component: React.FC<PropsType> = ({
    office,
    isSelected,
    isLastSelectedOffice,
    mapsService,
    mapLocation,
    queryParams,
    lang,
    selectedCity,
    onClick,
    setMapLocation,
    setMapZoom,
    exportOffice,
}) => {
    const onExportOffice = React.useCallback(() => {
        if (!queryParams.shopUrl || !exportOffice) {
            return;
        }

        const output: IOfficeLocatorOutput = {
            office: {
                id: office.id,
                code: office.code,
                name: getEcontItemName(office, lang),
                address: {
                    ...office.address,
                    city: {
                        ...office.address.city,
                        zoneId: selectedCity?.zoneId || null,
                        zoneName: selectedCity?.zoneName || null,
                        zoneNameEn: selectedCity?.zoneNameEn || null,
                    },
                },
                type: officeTypeEnumToString(office.type),
            },
        };

        exportOffice?.(output);
    }, [exportOffice, lang, office, queryParams.shopUrl, selectedCity]);

    const onOfficeClicked = React.useCallback((office: IOffice) => {
        if (!isSelected) {
            setMapLocation({ ...office.address.location });
            setMapZoom(17);
        }

        onClick?.(office);
    }, [isSelected, onClick, setMapLocation, setMapZoom]);

    return (
        <div id={`office-item-${office.id}`} data-test-id={`office-item-${office.id}`} className={`office-address-item ${isSelected && 'select'} ${!isSelected && isLastSelectedOffice && 'visited'}`}>
            <OfficeAddress lang={lang} onClick={onOfficeClicked} office={office} isSelected={isSelected} isLastSelectedOffice={!isSelected && isLastSelectedOffice} mapLocation={mapLocation} />
            {
                isSelected &&
                <>
                    <OfficeWorkTime office={office} />
                    <OfficeContacts office={office} />
                    <OfficeRoutes office={office} mapsService={mapsService} />
                    {queryParams.shopUrl && <OfficeExportButton onClick={onExportOffice} />}
                </>
            }
        </div>
    )
};

const mapsService = getMapsService();

function exportOffice(output: IOfficeLocatorOutput) {
    window.parent?.postMessage(output, '*');
}
function mapStateToProps(state: IApplicationState): IStateProps {
    return {
        mapsService,
        lang: state.app.lang,
        mapLocation: state.map.location,
        queryParams: state.app.queryParams,
        exportOffice,
        selectedCity: state.city.selectedCity,
    };
}

export const ListItemContainer = connect<IStateProps, IMapActions, IOwnProps, IApplicationState>(mapStateToProps, MapActions)(Component);
