import { IAddress, ICity, OfficeType } from "models";

export type Optional<T> = T | undefined;

export interface ILocation {
    longitude: number;
    latitude: number;
    addressText?: string;
}

export interface ILocationAddress {
    addressText: string;
    streetName?: string | null;
    streetNum?: string | null;
    cityNames: string[];
}

export interface IPoint {
    x: number;
    y: number;
}

export interface IRoute {
    start: ILocation;
    destination: ILocation;
	type: RouteType;
	steps: ILocation[];
    distance?: string;
    duration?: string;
}

export enum RouteType {
    Walking = 'WALKING',
    Driving = 'DRIVING',
}

export interface IErrorInfo {
	genericMessage: string;
	errorObject?: any;
}

export interface ISelectedOfficeOutput {
    id: string;
    code: string;
    name: string;
    type: string;
    address: IAddress;
}

export interface ILocationIconStyleProps {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
}

export interface IOfficeLocatorOutput {
    office: ISelectedOfficeOutput;
}

export interface IAddressOutput {
    address: {
        streetNum?: string | null;
        streetName?: string | null;
        city?: ICity;
        fullAddress: string;
    }
}

export interface IOfficeLocatorInput {
    shopUrl?: string;
    city?: string;
    address?: string;
    lang?: string;
    officeType?: OfficeType;
    location?: ILocation;
    skipLocation: Boolean;
    hideDrawer: Boolean;
    hideFilter: Boolean;
    hideMagnifyingGlass: Boolean;
    chooseOfficeHeader: String | null;
    filterOut?: string[];
    showLC?: boolean;
    currentLocationIcon?: ILocationIconStyleProps;
}

export interface IQueryParams {
    shopUrl?: string;
    city?: string;
    address?: string;
    officeType?: 'office' | 'aps' | 'mps';
    lang?: string;
    location?: string;
}

export interface IPadding {
    left: number;
    top: number;
    right: number;
    bottom: number;
}

export interface IInfoMessage {
    title: string;
    text: string;
    iconUrl: string;
    titleClassName?: string;
	retry?: () => void;
    clear?: () => void;
}

export interface IBoundsInfo {
    bounds: ILocation[];
    padding: IPadding;
}

export enum MessageType {
    Error,
    Warning,
    Info,
}