import { IEcontService, IHttpService } from 'services/contracts';
import { createHttpService } from 'services/HttpService';
import {
    ICity,
    IOffice,
    IStreet,
    ICityResponseDto,
    IOfficeResponseDto,
    IStreetResponseDto,
    dtoToCity,
    dtoToOffice,
    IEcontAutoSuggestPostPayload,
    SearchableType,
} from 'models';
import { nomenclaturesUrl, apiEnpoint } from 'env';

class EcontService implements IEcontService {
    constructor(private readonly httpApi: IHttpService) { }

    async fetchOffices(cityID: number, showLC?: boolean, showCargoReceptions?: boolean): Promise<IOffice[]> {
        const url = `${nomenclaturesUrl}.getOffices.json`;
        const rensponseDtoOffices = await this.httpApi.post<IOfficeResponseDto>(
            url,
            {
                cityID,
                showLC,
                showCargoReceptions,
                servingReceptions: true,
            });
        return rensponseDtoOffices.offices.map(dtoToOffice);
    }

    async fetchStreets(cityID: number): Promise<IStreet[]> {
        const url = `${nomenclaturesUrl}.getStreets.json`;
        const responseDto = await this.httpApi.post<IStreetResponseDto>(url, { cityID });
        return responseDto.streets.map(s => ({
            name: s.name,
            nameEn: s.nameEn,
            searchableType: SearchableType.Street,
            searchField: `${s.name} ${s.nameEn}`.toLowerCase(),
        }));
    }

    async fetchCities(query: string): Promise<ICity[]> {
        const data: IEcontAutoSuggestPostPayload = {
            jsonrpc: '2.0',
            method: 'ApiExternalSuggest.getCities',
            params: {
                text: query,
            },
        };
        const responseDtoCities = await this.httpApi.post<ICityResponseDto>(apiEnpoint, data);
        return (responseDtoCities.result.cities || []).map(dtoToCity);
    }
}

export function createEcontService() {
    return new EcontService(createHttpService());
}
