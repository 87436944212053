import { ILocation } from 'contracts';
import * as React from 'react';

import NavigationIcon from 'assets/navigation icon.svg';
import NavigationIconEmpty from 'assets/navigation icon empty.svg';
import { useIntl } from 'react-intl';
import { parseQueryParams } from 'store/slices/utils';

interface IProps {
    userLocation?: ILocation;
    onLocationButtonPress?: () => void;
}

export const DrawerHeader: React.FC<IProps> = ({ userLocation, onLocationButtonPress }) => {
    const { $t } = useIntl();
    const officeHeaderKey = parseQueryParams().chooseOfficeHeader;

    const getHeader = React.useCallback(() => {
      if (!officeHeaderKey) {
        return $t({ id: 'office.choose' });
      }
      
      const header = $t({ id: officeHeaderKey as string, defaultMessage: 'Избери наш офис до теб' });

      return header;
    }, [officeHeaderKey, $t]);

    return (
        <h2 data-test-id='drawer-header' id="drawer-header">
            {getHeader()}
            <img
                onClick={onLocationButtonPress}
                className='location-button'
                src={userLocation ? NavigationIcon : NavigationIconEmpty}
                alt='navigation icon'
                data-test-id='location-button' />
        </h2>
    );
};
