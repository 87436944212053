import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import { IAddressOutput, ILocation, ILocationAddress, ILocationIconStyleProps } from "contracts";
import { ICity } from "models";
import { IMapsService } from "services/contracts";
import { IAppActions, ICityActions, IMapActions, MapActions, CityActions, AppActions } from "store/slices";
import { IApplicationState } from "store/contracts";
import { getMapsService } from "services";
import { findCity } from "services/utils";

import LocationIcon from 'assets/Location_Icon.svg';
import WarningIcon from 'assets/images/warning-icon.svg';

interface IStateProps {
    userLocation?: ILocation;
    selectedCity?: ICity;
    mapsService: IMapsService;
}

type ActionsType = ICityActions & IMapActions & IAppActions;
type PropsType = IStateProps & ActionsType & {
    setLoading: (value: boolean) => void;
    positionProps: ILocationIconStyleProps;
};


const Component: React.FC<PropsType> = ({
    mapsService,
    userLocation,
    selectedCity,
    setInfoMessage,
    clearInfoMessage,
    setSearchedLocation,
    setMapLocation,
    fetchCities,
    setSelectedCity,
    hasLocationPermission,
    requestUserLocation,
    setLoading,
    positionProps,
}) => {
    const [locationRequested, setLocationRequested] = useState(false);
    const { $t } = useIntl();

    const fetchAddressAndGo = useCallback(async () => {
        setLocationRequested(false);

        if (!userLocation) {
            return;
        }

        const addressInfo = await mapsService.getLocationAddress(userLocation);

        let cityData = undefined;

        if (addressInfo?.cityNames) {
            await findCity(addressInfo.cityNames, fetchCities)
              .then(city => {
                  cityData = city;
                  if (city && city.id !== selectedCity?.id) {
                      // Set selected city only if it's different
                      // so that we don't reload the offices.
                      setSelectedCity(city);
                  }
              });

            exportAddress(addressInfo, cityData);
        } else {
            setSelectedCity(undefined);
        }

        setSearchedLocation({
            ...userLocation,
            addressText: addressInfo?.addressText,
        });
        // Create new user location object to trigger the effects
        // that depend on the map location
        setMapLocation({ ...userLocation });
    }, [fetchCities, mapsService, selectedCity?.id, setMapLocation, setSearchedLocation, setSelectedCity, userLocation]);

    const onGoToUserLocation = React.useCallback(async () => {
        const hasPermission = await hasLocationPermission();
        if (!hasPermission) {
            setInfoMessage({
                iconUrl: WarningIcon,
                title: $t({ id: 'app.location.permission.title' }),
                text: $t({ id: 'app.location.permission.text' }),
                titleClassName: 'warning',
                clear: clearInfoMessage,
            });
            return;
        }

        if (!userLocation) {
            requestUserLocation();
        }

        setLocationRequested(true);

    }, [$t, clearInfoMessage, hasLocationPermission, requestUserLocation, setInfoMessage, userLocation]);

    useEffect(() => {
        if (userLocation && locationRequested) {
            fetchAddressAndGo();
        }
    }, [userLocation, locationRequested, fetchAddressAndGo]);

  return (
    <img style={{ position: 'absolute', zIndex: 1, ...positionProps }} src={LocationIcon} alt="Location icon not found" onClick={onGoToUserLocation}/>
  )
};

const mapsService = getMapsService();

function exportAddress(addressInfo: ILocationAddress, cityData?: ICity) {
    const { cityNames, addressText, ...rest } = addressInfo;

    const output: IAddressOutput = {
        address: {
            ...rest,
            fullAddress: addressText,
            city: cityData,
        }
    };

    window.parent?.postMessage(output, '*');
}

function mapStateToProps(state: IApplicationState): IStateProps {
    return {
        mapsService,
        userLocation: state.map.userLocation,
        selectedCity: state.city.selectedCity,
    };
}

export const LocationIconButton = connect<IStateProps, ActionsType, {}, IApplicationState>(mapStateToProps, {
    ...MapActions,
    ...CityActions,
    ...AppActions,
})(Component); ;
