import { combineReducers } from "redux";
import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';

import { AppState, CityState, MapState, OfficeState, LocationSearchState } from "./slices";

const reducers = combineReducers({
	app: AppState.reducer,
	city: CityState.reducer,
	locationSearch: LocationSearchState.reducer,
	map: MapState.reducer,
	office: OfficeState.reducer,
});

export const Store = configureStore({
  reducer: reducers,
  middleware: [thunkMiddleware],
});
