import { OfficeType } from 'models';
import IconEcontomat from 'assets/images/marker-econtomat.svg';
import IconMobileOffice from 'assets/images/marker-mobile-office.svg';
import IconOffice from 'assets/images/marker-office.svg';
import IconDrive from 'assets/images/marker-econtdrive.svg';
import IconEcontomatSelected from 'assets/images/marker-econtomat-selected.svg';
import IconMobileOfficeSelected from 'assets/images/marker-mobile-office-selected.svg';
import IconOfficeSelected from 'assets/images/marker-office-selected.svg';
import IconDriveSelected from 'assets/images/marker-econtdrive-selected.svg';

export function getOfficeMarkerIconState(officeType: OfficeType, shouldUseSelectedIcon?: boolean): string {
    switch (officeType) {
        case OfficeType.MobileOffice:
            return shouldUseSelectedIcon ? IconMobileOfficeSelected : IconMobileOffice;
        case OfficeType.Office:
            return shouldUseSelectedIcon ? IconOfficeSelected : IconOffice;
        case OfficeType.SelfService:
            return shouldUseSelectedIcon ? IconEcontomatSelected : IconEcontomat;
        case OfficeType.Drive:
            return shouldUseSelectedIcon ? IconDriveSelected : IconDrive;
        default:
            throw new Error(`Unknown office type ${officeType}`);
    }
}
