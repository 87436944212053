import * as React from 'react';

import MapFilterContent from './MapFilterContent';
import IconRightArrow from '../../../assets/navigation_arrow_right.svg'
import IconLeftArrow from '../../../assets/navigation_arrow_left.svg'
import IconClose from '../../../assets/close.svg'
import { OfficeType } from 'models';

import 'components/map/filter/MapFilter.scss';
import { useResponsive } from 'hooks';

interface IProps {
	selectedOfficeType?: OfficeType;
	onFilterStateChange?: (isOpen: boolean) => void;
	onFilter?: (type?: OfficeType) => void;
	removedFilters?: string[];
}

export const MapFilter: React.FC<IProps> = ({ selectedOfficeType, onFilter, onFilterStateChange, removedFilters }) => {
	const { isTablet, isSmallDesktop, isPhoneLandscape } = useResponsive();
    const [contentVisibility, setContentVisibility] = React.useState(!isTablet && !isPhoneLandscape);
	const onFilterSelected = React.useCallback((selectedOfficeType?: OfficeType | undefined) => {
		if (isTablet) {
			const visibility = false;
			setContentVisibility(visibility);
			onFilterStateChange?.(visibility);
		}

		onFilter?.(selectedOfficeType);
	}, [isTablet, onFilter, onFilterStateChange]);

    const handleClick = React.useCallback(() => {
		const visibility = !contentVisibility;
    	setContentVisibility(visibility);
		onFilterStateChange?.(visibility);
    }, [contentVisibility, onFilterStateChange]);

	const getCollapseIcon = React.useCallback(() => {
	  if (isTablet || contentVisibility || isPhoneLandscape) {
		return IconRightArrow;
	  }

	  return IconLeftArrow;
	}, [contentVisibility, isTablet, isPhoneLandscape]);
	
	return (
		<div className={`map-filter ${isSmallDesktop ? 'small' : ''} ${contentVisibility ? 'open' : ''}`}>
			<div className={`map-filter-open-button${contentVisibility ? '' : ' closed'}`} 
				onClick={handleClick}
				data-test-id='toggle-office-type-filter-button'>
				<img className='filter-icon' src={getCollapseIcon()} alt="office filter" />
				<img className='close-icon' src={IconClose} alt="close filter" />
			</div>
			<MapFilterContent selectedType={selectedOfficeType} 
				onFilter={onFilterSelected}
				isVisible={contentVisibility}
				removedFilters={removedFilters} />
		</div>
    );
};
