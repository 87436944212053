import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

interface IResponsiveInfo {
	isPhone: boolean;
	isTablet: boolean;
	isPhoneLandscape: boolean;
	isPhonePortrait: boolean;
	isLandscape: boolean;
	isSmallDesktop: boolean;
}

export default function useResponsive(): IResponsiveInfo {
	const isPhonePortrait = useMediaQuery({ query: 'only screen and (max-width: 425px)' });
	const isPhoneLandscape = useMediaQuery({ query: 'only screen and (max-height: 425px)' });
	const isTablet = useMediaQuery({ query: 'only screen and (max-width: 768px)' });
	const isLandscape = useMediaQuery({ query: 'only screen and (orientation: landscape)' });
	const isSmallDesktop = useMediaQuery({ query: "only screen and (max-width: 685px) and (min-width: 426px)" });

	const result = useMemo(() => ({
		isPhonePortrait,
		isPhoneLandscape,
		isPhone: isPhonePortrait || isPhoneLandscape,
		isTablet,
		isLandscape,
		isSmallDesktop,
	}), [isLandscape, isPhoneLandscape, isPhonePortrait, isSmallDesktop, isTablet]);

	return result;
}
