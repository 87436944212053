import * as React from 'react';
import { getEcontItemName, getEcontFullAddresName, IOffice } from 'models';
import { getOfficeMarkerIconState } from 'containers/utils'
import 'containers/ListItemContainer.scss';
import { ILocation } from 'contracts';

interface ItemProps {
  isSelected?: boolean;
  isLastSelectedOffice?: boolean;
  mapLocation?: ILocation;
  office: IOffice;
  lang: string;
  onClick?: (office: IOffice) => void;
}

function getOfficeMarkerIcon(office: IOffice, isOfficeSelected?: boolean, isLastSelectedOffice?: boolean): string {
  const shouldUseSelectedIcon = isOfficeSelected || isLastSelectedOffice;
  return getOfficeMarkerIconState(office.type, shouldUseSelectedIcon)
}

const OfficeAddress: React.FC<ItemProps> = ({ isSelected, isLastSelectedOffice, office, lang, onClick }) => {
  return (
    <div onClick={() => onClick?.(office)} className='office-address-head'>
      <img alt='marker' className='marker' src={getOfficeMarkerIcon(office, isSelected, isLastSelectedOffice)} />
      <div className='office-address-name'>
        <strong>{getEcontItemName(office, lang)}</strong>
        {office.address.city.postCode}, {getEcontFullAddresName(office.address, lang)}
      </div>
      {/* {!isOfficeSelected &&
        <div className='office-address-distance'>{distance}м</div>} */}
    </div>
  );
};

export default OfficeAddress;
