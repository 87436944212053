import { Action } from '@reduxjs/toolkit';

export interface ILocationSearchState {
	cityQuery: string,
	addressQuery: string,
}

export interface ILocationSearchActions {
	setCityQuery: (cityQuery: string) => void;
	setAddressQuery: (addressQuery: string) => void;
}

enum ActionTypes {
	SET_CITY_QUERY = 'SET_CITY_QUERY',
	SET_ADDRESS_QUERY = 'SET_ADDRESS_QUERY',
}

interface ActionSetCityQuery extends Action<ActionTypes> {
	type: ActionTypes.SET_CITY_QUERY,
	data: string;
}
interface ActionSetAddressQuery extends Action<ActionTypes> {
	type: ActionTypes.SET_ADDRESS_QUERY,
	data: string;
}
type AllActions = ActionSetCityQuery | ActionSetAddressQuery;

const initialState: ILocationSearchState = {
	cityQuery: '',
	addressQuery: '',
};

class LocationSearchSlice {
	reducer(state: ILocationSearchState = initialState, action: AllActions) {
		if (action.type === ActionTypes.SET_CITY_QUERY) {
			return { ...state, cityQuery: action.data };
		}

		if (action.type === ActionTypes.SET_ADDRESS_QUERY) {
			return { ...state, addressQuery: action.data };
		}

		return state;
	}

	setCityQuery(cityQuery: string): ActionSetCityQuery {
		return {
			type: ActionTypes.SET_CITY_QUERY,
			data: cityQuery
		}
	}

	setAddressQuery(addressQuery: string): ActionSetAddressQuery {
		return {
			type: ActionTypes.SET_ADDRESS_QUERY,
			data: addressQuery
		}
	}
}

export const LocationSearchState = new LocationSearchSlice();
export const LocationSearchActions: ILocationSearchActions = {
	setCityQuery: (cityQuery: string) => LocationSearchState.setCityQuery(cityQuery),
	setAddressQuery: (addressQuery: string) => LocationSearchState.setAddressQuery(addressQuery),
};
