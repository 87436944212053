import * as React from 'react';

import { IOffice } from 'models';
import { ListItemContainer } from 'containers';
import { IApplicationState } from 'store/contracts';
import { connect } from 'react-redux';
import { IOfficeActions, OfficeActions } from 'store/slices/offices';
import BackwardIcon from 'assets/phone-arrow-back.svg';

import './OfficePopup.scss';
import { useIntl } from 'react-intl';

interface IOwnProps {
    office: IOffice;
}

type DispatchProps = IOfficeActions;
type PropsType = IOwnProps & DispatchProps;

export function Component({ office, setSelectedOffice }: PropsType) {
    const onBackPress = React.useCallback(() => {
        setSelectedOffice(undefined);
    }, [setSelectedOffice]);
    const { $t } = useIntl();
    return (
        <div className='office-popup'>
            <ListItemContainer isSelected={true} office={office} />
            <div className='office-popup-back-button' onClick={onBackPress}>
                <img className='office-popup-back-butto-image' alt="back to office list" src={ BackwardIcon } />
                <div className='office-popup-back-button-text'>{$t({ id: 'office.back-to-list' })}</div> 
            </div>
        </div>
    );
}

export const OfficePopup = connect<{}, DispatchProps, IOwnProps, IApplicationState>(undefined, {
    ...OfficeActions,
})(Component);