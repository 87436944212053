import * as React from 'react';
import { IOffice } from 'models';
import PhoneIcon from '../../assets/images/phone.svg';
import 'containers/ListItemContainer.scss';

interface ItemProps {
	office: IOffice;
}

const OfficeContacts: React.FC<ItemProps> = ({office}) => {
	return (
		<div className='office-contact-phone'>             
			<img className='image' alt='phone' src={PhoneIcon}/>
			<a className='link' href={`tel:${office.phone}`}>{office.phone}</a>
		</div> 
    )
};

export default OfficeContacts;
