import { Action, ThunkAction } from '@reduxjs/toolkit';
import { FetchOfficeParams, IOffice, OfficeType } from 'models'
import { IEcontService } from 'services/contracts';
import { createEcontService } from 'services/EcontService';
import { IApplicationState } from 'store/contracts';
import { parseQueryParams } from './utils';

export interface IOfficesState {
	offices: IOffice[];
	selectedOffice?: IOffice;
	lastSelectedOffice?: IOffice;
	visibleOfficesType?: OfficeType;
}

export interface IOfficeActions {
	fetchOffices: (officeParams: FetchOfficeParams, filterFn: (office: IOffice) => boolean) => Promise<IOffice[]>;
	setVisibleOfficeType: (type?: OfficeType) => void;
	setSelectedOffice: (office?: IOffice) => void;
	setOffices: (offices: IOffice[]) => void;
}

enum ActionTypes {
	SET_OFFICES = 'SET_OFFICES',
	SET_SELECTED_OFFICE = 'SET_SELECTED_OFFICE',
	SET_VISIBLE_OFFICES_TYPE = 'SET_VISIBLE_OFFICES_TYPE',
}

interface ActionSetOffices extends Action<ActionTypes> {
	type: ActionTypes.SET_OFFICES,
	data: IOffice[];
}
interface ActionSetSelectedOffice extends Action<ActionTypes> {
	type: ActionTypes.SET_SELECTED_OFFICE,
	data?: IOffice;
}
interface ActionSetVisibleOfficesType extends Action<ActionTypes> {
	type: ActionTypes.SET_VISIBLE_OFFICES_TYPE,
	data?: OfficeType;
}
type AllActions = ActionSetOffices | ActionSetSelectedOffice | ActionSetVisibleOfficesType;

const initialState: IOfficesState = {
	offices: [],
	visibleOfficesType: parseQueryParams().officeType,
};

class OfficeSlice {
	constructor(private readonly econtService: IEcontService) { }

	reducer(state: IOfficesState = initialState, action: AllActions) {
		if (action.type === ActionTypes.SET_OFFICES) {
			return { ...state, offices: action.data };
		}

		if (action.type === ActionTypes.SET_SELECTED_OFFICE) {
			return (action.data === undefined) ? { ...state, selectedOffice: action.data } :
				{ ...state, selectedOffice: action.data, lastSelectedOffice: action.data };
		}

		if (action.type === ActionTypes.SET_VISIBLE_OFFICES_TYPE) {
			return { ...state, visibleOfficesType: action.data };
		}

		return state;
	}

	setOffices(offices: IOffice[]): ActionSetOffices {
		return {
			type: ActionTypes.SET_OFFICES,
			data: offices,
		};
	}

	fetchOffices(officeParams: FetchOfficeParams, filterFn?: (office: IOffice) => boolean): ThunkAction<Promise<IOffice[]>, IApplicationState, unknown, ActionSetOffices> {
		return async dispatch => {
			const { cityID, showLC, showCargoReceptions } = officeParams;
			let offices = await this.econtService.fetchOffices(cityID, showLC, showCargoReceptions);

			if (filterFn) {
				offices = offices.filter(filterFn);
			}

			dispatch(this.setOffices(offices));
			return offices;
		}
	}

	setSelectedOffice(office?: IOffice): ActionSetSelectedOffice {
		return {
			type: ActionTypes.SET_SELECTED_OFFICE,
			data: office,
		};
	}

	setVisibleOfficesType(type?: OfficeType): ActionSetVisibleOfficesType {
		return {
			type: ActionTypes.SET_VISIBLE_OFFICES_TYPE,
			data: type,
		};
	}
}

export const OfficeState = new OfficeSlice(createEcontService());
export const OfficeActions: IOfficeActions = {
	fetchOffices: (officeParams: FetchOfficeParams, filterFn: (office: IOffice) => boolean) => OfficeState.fetchOffices(officeParams, filterFn),
	setSelectedOffice: (office?: IOffice) => OfficeState.setSelectedOffice(office),
	setOffices: (offices: IOffice[]) => OfficeState.setOffices(offices),
	setVisibleOfficeType: (type?: OfficeType) => OfficeState.setVisibleOfficesType(type),
} as any;
