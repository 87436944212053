import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { ICity } from 'models';
import { getMapsService } from 'services';
import { ILocation } from 'contracts';

import { IMapActions, MapActions } from 'store/slices/map';
import { IApplicationState } from 'store/contracts';
import { IMapsService } from 'services/contracts';
import { ICityActions, CityActions } from 'store/slices/cities';
import { AppActions, IAppActions } from 'store/slices';
import { DrawerHeader } from './DrawerHeader';

import './ListItemContainer.scss'

import WarningIcon from '../assets/images/warning-icon.svg';
import { findCity } from 'services/utils';

interface IStateProps {
    userLocation?: ILocation;
    selectedCity?: ICity;
    mapsService: IMapsService;
}

type ActionsType = ICityActions & IMapActions & IAppActions;
type PropsType = IStateProps & ActionsType;

const Component: React.FC<PropsType> = ({
    mapsService,
    userLocation,
    selectedCity,
    setInfoMessage,
    clearInfoMessage,
    setSearchedLocation,
    setMapLocation,
    fetchCities,
    setSelectedCity,
    hasLocationPermission,
    requestUserLocation,
}) => {
    const [locationRequested, setLocationRequested] = React.useState(false);

    const { $t } = useIntl();

    const fetchAddressAndGo = React.useCallback(async () => {
        setLocationRequested(false);

        if (!userLocation) {
            return;
        }

        const addressInfo = await mapsService.getLocationAddress(userLocation);

        if (addressInfo?.cityNames) {
            await findCity(addressInfo.cityNames, fetchCities)
              .then(city => {
                  if (city && city.id !== selectedCity?.id) {
                      // Set selected city only if it's different
                      // so that we don't reload the offices.
                      setSelectedCity(city);
                  }
              });
        } else {
            setSelectedCity(undefined);
        }

        setSearchedLocation({
            ...userLocation,
            addressText: addressInfo?.addressText,
        });
        // Create new user location object to trigger the effects
        // that depend on the map location
        setMapLocation({ ...userLocation });
    }, [fetchCities, mapsService, selectedCity?.id, setMapLocation, setSearchedLocation, setSelectedCity, userLocation]);

    const onGoToUserLocation = React.useCallback(async () => {
        const hasPermission = await hasLocationPermission();
        if (!hasPermission) {
            setInfoMessage({
                iconUrl: WarningIcon,
                title: $t({ id: 'app.location.permission.title' }),
                text: $t({ id: 'app.location.permission.text' }),
                titleClassName: 'warning',
                clear: clearInfoMessage,
            });
            return;
        }

        if (!userLocation) {
            requestUserLocation();
        }

        setLocationRequested(true);

    }, [$t, clearInfoMessage, hasLocationPermission, requestUserLocation, setInfoMessage, userLocation]);

    React.useEffect(() => {
        if (userLocation && locationRequested) {
            fetchAddressAndGo();
        }
    }, [userLocation, locationRequested, fetchAddressAndGo]);

	return <DrawerHeader userLocation={userLocation} onLocationButtonPress={onGoToUserLocation} />
};

const mapsService = getMapsService();
function mapStateToProps(state: IApplicationState): IStateProps {
    return {
        mapsService,
        userLocation: state.map.userLocation,
        selectedCity: state.city.selectedCity,
    };
}

export const DrawerHeaderContainer = connect<IStateProps, ActionsType, {}, IApplicationState>(mapStateToProps, {
    ...MapActions,
    ...CityActions,
    ...AppActions,
})(Component); ;