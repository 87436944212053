import React from 'react';
import { useIntl } from 'react-intl';
import { IErrorInfo } from 'contracts';

interface INetworkRequest<T> {
	isLoading: boolean;
	error?: IErrorInfo;
	fetch: (callback: () => Promise<T>) => Promise<T | undefined>;
	clearError: () => void;
}

function createErrorInfo(genericMessage: string, errorResponse: any) {
	if (!errorResponse) {
		return undefined;
	}

	return {
		errorObject: errorResponse,
		genericMessage,
	};
}

class NetworkRequest<T> implements INetworkRequest<T> {
	isLoading: boolean = false;
	error?: IErrorInfo | undefined;
	counter: number = 0;

	constructor(
		private readonly formatMessage: (id: string) => string,
		private readonly update: (count: number) => void,
	) {
	}

	async fetch(callback: () => Promise<T>) {
		if (this.isLoading) {
			return undefined;
		}

		let errorResponse: any;
		try {
			this.isLoading = true;
			this.error = undefined;
			this.counter += 1;
			this.update(this.counter);

			const res = await callback();
			return res;
		} catch (err: any) {
			errorResponse = err;
		} finally {
			const genericMessage = this.formatMessage('app.generic-error');
			this.isLoading = false;
			this.error = createErrorInfo(genericMessage, errorResponse);
			this.counter += 1;
			this.update(this.counter);
		}
	}

	clearError() {
		this.error = undefined;
		this.counter += 1;
		this.update(this.counter);
	}
}

export default function useNetworkRequest<T>(): INetworkRequest<T> {
	const { $t } = useIntl();
	const state = React.useState(0);
	return React.useState<NetworkRequest<T>>(new NetworkRequest<T>(id => $t({ id }), state[1]))[0];
}
