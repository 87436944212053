import { ILocation, ILocationIconStyleProps, IOfficeLocatorInput, Optional } from "contracts";
import { SupportedLanguages } from "localization";
import { OfficeType } from "models";

const supportedLanguages: string[] = [SupportedLanguages.Bulgarian, SupportedLanguages.English];

function parseOfficeType(type: string | null): Optional<OfficeType> {
	switch (type) {
		case 'aps':
			return OfficeType.SelfService;
		case 'mps':
			return OfficeType.MobileOffice;
		case 'drive':
			return OfficeType.Drive;
		case 'office':
			return OfficeType.Office;
	}

	return undefined;
}

function parseLocation(locationString: string | null): Optional<ILocation> {
	if (!locationString) {
		return;
	}

	const components = locationString.split(',');
	if (components.length !== 2) {
		return;
	}

	const latitude = Number.parseFloat(components[0]);
	const longitude = Number.parseFloat(components[1]);

	if (isNaN(latitude) || isNaN(longitude)) {
		return;
	}

	return {
		latitude,
		longitude,
	}
}

function parseFilters(filtersString: string | null): Optional<string[]> {
  if (!filtersString) {
	return;
  }

  const filters = filtersString.split(',').map(f => f.trim());

  return filters;
}

function parseLocationIconProps(value: string | null): ILocationIconStyleProps | undefined {
	if (!value) {
		return undefined;
	}

	return value.split(';').reduce<ILocationIconStyleProps>((prev: ILocationIconStyleProps, next: string) => {
		if (next.startsWith('left')) {
			prev.left = next.split(':')[1];
		} else if (next.startsWith('right')) {
			prev.right = next.split(':')[1];
		} else if (next.startsWith('bottom')) {
			prev.bottom = next.split(':')[1];
		} else if (next.startsWith('top')) {
			prev.top = next.split(':')[1];
		}

		return prev;
	}, {});
}

export function parseQueryParams(): IOfficeLocatorInput {
	const url = new URL(window.location.href);
	const params = new URLSearchParams(url.search);

	return {
		shopUrl: params.get('shopUrl') || undefined,
		officeType: parseOfficeType(params.get('officeType')),
		city: params.get('city') || undefined,
		address: params.get('address') || undefined,
		location: parseLocation(params.get('location')),
		lang: params.get('lang') || undefined,
		skipLocation: Boolean(params.get('skipLocation')) || false,
		hideDrawer: Boolean(params.get('hideDrawer')) || false,
		hideFilter: Boolean(params.get('hideFilter')) || false,
		hideMagnifyingGlass: Boolean(params.get('hideMagnifyingGlass')) || false,
		chooseOfficeHeader: params.get('chooseOfficeHeader'),
		filterOut: parseFilters(params.get('filterOut')) || undefined,
		showLC: Boolean(params.get('showLC')) || false,
		currentLocationIcon: parseLocationIconProps(params.get('currentLocationIcon')) || undefined,
	};
}

export function getLang(): string {
	const queryParams: IOfficeLocatorInput = parseQueryParams();
	let currentLanguage: string = queryParams.lang || SupportedLanguages.Bulgarian;
	if (!supportedLanguages.includes(currentLanguage)) {
		console.warn(`Unsupported language ${currentLanguage} was provided as query parameter!`)
		currentLanguage = SupportedLanguages.Bulgarian;
	}

	return currentLanguage;
}