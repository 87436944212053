import React from 'react';

import { InputContainer, OfficeListContainer, DrawerHeaderContainer } from 'containers';

import './DrawerContent.scss';

export const DrawerContent: React.FC = () => {
  return (
    <div className='drawer-inner-content' data-test-id='drawer-inner-content'>
      <div className='select-office'>
        <DrawerHeaderContainer />
        <InputContainer />
      </div>
      <OfficeListContainer />
    </div>
  );
};