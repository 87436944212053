import * as React from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';
import { useIntl } from 'react-intl';

import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteFocusEvent } from "@progress/kendo-react-dropdowns";
import { FloatingLabel } from "@progress/kendo-react-labels";

import { CityAutoComplete, getEcontItemField, getEcontItemFieldCities, getEcontItemName, getCityDescription, getCountryOfCity, ICity, IOffice, ISearchable, IStreet, SearchableType } from 'models';
import { IApplicationState } from 'store/contracts';
import WarningIcon from '../assets/images/warning-icon.svg';

import {
    AppActions,
    CityActions,
    IAppActions,
    ICityActions,
    ILocationSearchActions,
    IMapActions,
    IOfficeActions,
    LocationSearchActions,
    MapActions,
    OfficeActions
} from 'store/slices';

import { IMapsService } from 'services/contracts';
import { getMapsService } from 'services/MapsService';
import { ILocation } from 'contracts';
import { useNetworkRequest, useResponsive } from 'hooks';
import ErrorIcon from '../assets/images/error.svg';

import './InputContainer.scss';

const DEBOUNCE_INTERVAL = 500;
const CITY_ID = 'city-input';
const ADDRESS_ID = 'address-input';

interface IStateProps {
    offices: IOffice[];
    cities: ICity[];
    streets: IStreet[];
    autoCompleteSource: CityAutoComplete[],
    selectedCity?: ICity;
    selectedOffice?: IOffice;
    mapsService: IMapsService;
    userLocation?: ILocation;
    searchedLocation?: ILocation;
    locationSearchCityQuery: string,
    locationSearchAddressQuery: string,
    lang: string;
}

type DispatchProps = ICityActions & IMapActions & ILocationSearchActions & IAppActions & IOfficeActions;

type Props = IStateProps & DispatchProps;

const Component: React.FC<Props> = ({
    cities,
    streets,
    offices,
    selectedCity,
    autoCompleteSource,
    mapsService,
    searchedLocation,
    locationSearchCityQuery,
    locationSearchAddressQuery,
    lang,
    setInfoMessage,
    clearInfoMessage,
    setSelectedCity,
    setSelectedOffice,
    fetchCities,
    fetchStreets,
    setSearchedLocation,
    setMapLocation,
    setCityQuery,
    setAddressQuery,
}) => {
    const { $t } = useIntl();
    const [filteredObjects, setFilteredObjects] = React.useState<ISearchable[]>([]);
    const streetsRequest = useNetworkRequest<IStreet[]>();
    const citiesRequest = useNetworkRequest<ICity[]>();
    const { isTablet } = useResponsive();

    React.useEffect(() => {
        if (streetsRequest.error || citiesRequest.error) {
            setInfoMessage({
                iconUrl: ErrorIcon,
                title: $t({ id: 'app.error-title' }),
                text: $t({ id: 'app.generic-error' }),
                titleClassName: 'error',
                clear: clearInfoMessage,
            });
        }
    }, [$t, citiesRequest.error, clearInfoMessage, setInfoMessage, streetsRequest.error]);

    React.useEffect(() => {
        if (!selectedCity) {
            return;
        }

        streetsRequest.fetch(() => fetchStreets(selectedCity.id));
        setCityQuery(getCityDescription(selectedCity, lang));
    }, [fetchStreets, setCityQuery, selectedCity, streetsRequest, lang]);

    React.useEffect(() => {
        if (!locationSearchCityQuery && !locationSearchAddressQuery) {
            setSearchedLocation(undefined);
        }
    }, [locationSearchAddressQuery, locationSearchCityQuery, setSearchedLocation]);

    React.useEffect(() => {
        if (!selectedCity) {
            setCityQuery('');
            return;
        }

        const cityDescription: string = getCityDescription(selectedCity, lang);
        setCityQuery(cityDescription);
        setAddressQuery('');
    }, [lang, selectedCity, setAddressQuery, setCityQuery]);

    React.useEffect(() => {
        if (searchedLocation?.addressText) {
            setAddressQuery(searchedLocation.addressText);
            if (!selectedCity) {
                return;
            }
            setCityQuery(getCityDescription(selectedCity, lang));
        }
    }, [lang, searchedLocation, selectedCity, setCityQuery, setAddressQuery]);

    const filterStreets = React.useCallback(async (query: string) => {
        const tempStreets = streets.filter(s => s.searchField.includes(query));
        const tempOffices = offices.filter(o => o.searchField.includes(query));
        setFilteredObjects([
            ...tempStreets,
            ...tempOffices,
        ]);
    }, [offices, streets]);

    const onAddressChanged = async (event: AutoCompleteChangeEvent) => {
        const addressValue: string = event.value;
        setAddressQuery(addressValue);
        if (!addressValue || !selectedCity) {
            return;
        }

        filterStreets(addressValue.toLowerCase());
    };

    const onAddressFocus = React.useCallback(async (event: AutoCompleteFocusEvent) => {
        if (!isTablet) {
            return;
        }

        const input = (event.target.element?.firstChild as HTMLInputElement);
        const inputLength = event.target.value.length;

        setTimeout(() => {
            input.focus();
            input.setSelectionRange(inputLength, inputLength);
            input.scrollLeft = input.scrollWidth;
        }, 0);
    }, [isTablet]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchCities = React.useCallback(
        lodash.debounce(
            (query: string) => citiesRequest.fetch(() => fetchCities(query)),
            DEBOUNCE_INTERVAL
        ),
        [fetchCities]
    );

    const onCityChanged = (event: AutoCompleteChangeEvent) => {
        setCityQuery(event.value);
        setAddressQuery('');
        debouncedFetchCities(event.value);
    }

    const getAddressLocation = React.useCallback(async (city?: ICity, streetName?: string) => {
        if (!city) {
            return;
        }
        const addressQuery = `${getCityDescription(city, lang)} ${getCountryOfCity(city, lang)} ${streetName || ''}`;
        const location = await mapsService.getAddressLocation(addressQuery);

        if (location) {
            setSearchedLocation(location);
            setMapLocation(location);
        } else {
            setInfoMessage({
                iconUrl: WarningIcon,
                title: $t({ id: 'address.not.found.title' }),
                text: $t({ id: 'address.not.found.text' }),
                titleClassName: 'warning',
                clear: clearInfoMessage,
            });
        }
    }, [lang, mapsService, setSearchedLocation, setMapLocation, setInfoMessage, $t, clearInfoMessage]);

    const renderStreet = React.useCallback((li: any, itemProps: any) => {
        const itemChildren = (
            <div className='search-item' onClick={() => {
                const index: number = itemProps.index;
                const street = filteredObjects[index];
                blurElement(ADDRESS_ID);
                if (street.searchableType === SearchableType.Street) {
                    getAddressLocation(selectedCity, getEcontItemName((street as IStreet), lang));
                } else {
                    const office = street as IOffice;
                    setSelectedOffice(office);
                    setSearchedLocation(office.address.location);
                    setMapLocation(office.address.location);

                    setTimeout(() => {
                        setAddressQuery(office.address.fullAddress);
                    }, 10);
                }
            }}>
                {li.props.children}
            </div>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }, [filteredObjects, getAddressLocation, lang, selectedCity, setAddressQuery, setMapLocation, setSearchedLocation, setSelectedOffice]);

    const selectCity = React.useCallback(async (city?: ICity) => {
        setSelectedCity(city);
        setSearchedLocation(undefined);
        if (city) {
            streetsRequest.fetch(() => fetchStreets(city.id));
            getAddressLocation(city);
        }
    }, [fetchStreets, getAddressLocation, setSearchedLocation, setSelectedCity, streetsRequest]);

    const renderCity = React.useCallback((li: any, itemProps: any) => {
        const index: number = itemProps.index;
        const city = cities[index];
        const itemChildren = (
            <div className='search-item' onClick={() => {
                selectCity(city);
                blurElement(CITY_ID);
            }}>
                {li.props.children}
            </div>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }, [cities, selectCity]);

    return (
        <div className='input-container'>
            <div data-test-id='city-continer' onKeyUp={e => {
                if (e.key.toLowerCase() === 'enter') {
                    const city = cities.find(c => getEcontItemName(c, lang).toLowerCase() === locationSearchCityQuery.toLowerCase());
                    selectCity(city);
                    getAddressLocation(city);
                    blurElement(CITY_ID);
                }
            }}>
                <FloatingLabel
                    label={$t({ id: 'input.city' })}
                    editorId={CITY_ID}
                    editorValue={locationSearchCityQuery}>
                    <AutoComplete
                        id={CITY_ID}
                        popupSettings={{
                            className: autoCompleteSource.length === 0 ? 'hidden' : '',
                        }}
                        className='city-input'
                        onChange={onCityChanged}
                        onFocus={onAddressFocus}
                        data={autoCompleteSource}
                        textField={getEcontItemFieldCities(lang)}
                        itemRender={renderCity}
                        value={locationSearchCityQuery}
                        suggest
                        placeholder={$t({ id: 'input.city' })}
                    />
                </FloatingLabel>
            </div>
            <div className='address-container' data-test-id='address-container' onKeyUp={e => {
                if (e.key.toLowerCase() === 'enter') {
                    getAddressLocation(selectedCity, locationSearchAddressQuery);
                    blurElement(ADDRESS_ID)
                }
            }}>
                <FloatingLabel
                    label={$t({ id: 'input.address' })}
                    editorId={ADDRESS_ID}
                    editorValue={locationSearchAddressQuery}>
                    <AutoComplete
                        id={ADDRESS_ID}
                        popupSettings={{
                            className: filteredObjects.length === 0 ? 'hidden' : '',
                        }}
                        data={filteredObjects}
                        onChange={onAddressChanged}
                        onFocus={onAddressFocus}
                        value={locationSearchAddressQuery}
                        textField={getEcontItemField(lang)}
                        itemRender={renderStreet}
                        placeholder={$t({ id: 'input.address' })}
                    />
                </FloatingLabel>
            </div>
        </div>
    );
};

function mapStateToProps(state: IApplicationState): IStateProps {
    return {
        offices: state.office.offices,
        cities: state.city.cities,
        streets: state.city.streets,
        selectedCity: state.city.selectedCity,
        selectedOffice: state.office.selectedOffice,
        autoCompleteSource: state.city.autoCompleteSource,
        searchedLocation: state.map.searchedLocation,
        mapsService: getMapsService(),
        locationSearchCityQuery: state.locationSearch.cityQuery,
        locationSearchAddressQuery: state.locationSearch.addressQuery,
        lang: state.app.lang,
    };
}

const dispatchProps = {
    ...CityActions,
    ...MapActions,
    ...LocationSearchActions,
    ...AppActions,
    ...OfficeActions,
};

export const InputContainer = connect<IStateProps, DispatchProps, {}, IApplicationState>(mapStateToProps, dispatchProps)(Component);

function blurElement(id: string) {
    document.getElementById(id)?.blur();
}