import * as React from 'react';
import { IOffice } from 'models';
import WalkIcon from '../../assets/images/walk.svg'
import CarIcon from '../../assets/images/car.svg'
import WalkIconSelected from '../../assets/images/walk-selected.svg'
import CarIconSelected from '../../assets/images/car-selected.svg'
import { Button } from '@progress/kendo-react-buttons';
import { IMapsService } from 'services/contracts';
import { ILocation, IRoute, RouteType } from 'contracts';
import { IApplicationState } from 'store/contracts';
import { IMapActions, MapActions } from 'store/slices/map';
import { connect } from 'react-redux';

import 'containers/ListItemContainer.scss';
import { useIntl } from 'react-intl';

interface IProps {
	mapsService: IMapsService;
	office: IOffice;
}

interface IStateProps {
	searchedLocation?: ILocation;
}

type DispatchProps = IMapActions;

type PropsType = DispatchProps & IProps & IStateProps;

function durationToString(seconds: number, translate: (id: string) => string): string {
	const min = Math.trunc(seconds / 60);
	return `${min} ${translate('office.duration.min')}`;
}

function distanceToString(distance: number, translate: (id: string) => string): string {
	const km = Math.round(distance / 100) / 10;
	const meters = km * 1000;
	const result = km >= 1 ? km + ` ${translate('office.distance.kilometersShort')}` : meters + ` ${translate('office.distance.metersShort')}`;
	return result;
}

const Component: React.FC<PropsType> = ({
	office,
	mapsService,
	searchedLocation,
	setCurrentRoute
}) => {
	const [isWalkSelected, setIsWalkSelected] = React.useState(false);
	const [isCarSelected, setIsCarSelected] = React.useState(false);
	const [walkRoute, setWalkRoute] = React.useState<IRoute>();
	const [driveRoute, setDriveRoute] = React.useState<IRoute>();
	const { $t } = useIntl();

	const reset = React.useCallback(() => {
		setWalkRoute(undefined);
		setDriveRoute(undefined);
		setIsWalkSelected(false);
		setIsCarSelected(false);
	}, []);

	React.useEffect(() => {
		reset();
	}, [office, reset]);

	React.useEffect(() => {
		reset();
	}, [reset, searchedLocation]);

	const translate = React.useCallback((id: string) => $t({ id }), [$t]);

	const getWalkRouteInfo = React.useCallback(async () => {
		if (!searchedLocation) {
			return;
		}

		if (walkRoute) {
			return walkRoute;
		}

		const calculatedWalkTravel = await mapsService.calculateTravelDuration(searchedLocation, office.address.location, RouteType.Walking);
		const route: IRoute = {
			start: searchedLocation,
			destination: office.address.location,
			type: RouteType.Walking,
			steps: calculatedWalkTravel.steps,
			distance: distanceToString(calculatedWalkTravel.distance, translate),
			duration: durationToString(calculatedWalkTravel.duration, translate),
		};
		setWalkRoute(route);
		return route;
	}, [mapsService, office.address.location, searchedLocation, translate, walkRoute]);

	const getDriveRouteInfo = React.useCallback(async () => {
		if (!searchedLocation) {
			return;
		}

		if (driveRoute) {
			return driveRoute;
		}

		const calculatedCarTravel = await mapsService.calculateTravelDuration(searchedLocation, office.address.location, RouteType.Driving);
		const route: IRoute = {
			start: searchedLocation,
			destination: office.address.location,
			type: RouteType.Driving,
			steps: calculatedCarTravel.steps,
			distance: distanceToString(calculatedCarTravel.distance, translate),
			duration: durationToString(calculatedCarTravel.duration, translate),
		};
		setDriveRoute(route);
		return route;
	}, [driveRoute, mapsService, office.address.location, searchedLocation, translate]);

	const handleClickWalk = React.useCallback(async () => {
		setIsWalkSelected(true);
		setIsCarSelected(false);
		const route = await getWalkRouteInfo();
		setCurrentRoute(route);
	}, [getWalkRouteInfo, setCurrentRoute]);

	const handleClickCar = React.useCallback(async () => {
		setIsCarSelected(true);
		setIsWalkSelected(false);
		const route = await getDriveRouteInfo();
		setCurrentRoute(route);
	}, [getDriveRouteInfo, setCurrentRoute]);

	if (!searchedLocation) {
		return null;
	}
	return (
		<div className='office-address-action-btn'>
			<Button
				className={`btn btn-sm btn-primary-flat ${isWalkSelected && 'active'}`}
				data-test-id="btn-walk"
				onClick={handleClickWalk}>
				<img alt='walk' src={isWalkSelected ? WalkIconSelected : WalkIcon}></img>
				{(walkRoute !== undefined) ? <span> {walkRoute?.distance && <strong>{walkRoute.distance}</strong>}
					{walkRoute?.duration && <span>({walkRoute.duration})</span>}
				</span>
					: $t({ id: 'office.distance.walk' })}
			</Button>
			<Button
				className={`btn btn-sm btn-primary-flat ${isCarSelected && 'active'}`}
				data-test-id="btn-drive"
				onClick={handleClickCar}>
				<img alt='drive' src={isCarSelected ? CarIconSelected : CarIcon}></img>
				{(driveRoute !== undefined) ? <span> {driveRoute?.distance && <strong>{driveRoute.distance}</strong>}
					{driveRoute?.duration && <span>({driveRoute.duration})</span>}
				</span>
					: $t({ id: 'office.distance.drive' })}
			</Button>
		</div>
	)
};

function mapStateToProps(state: IApplicationState): IStateProps {
	return {
		searchedLocation: state.map.searchedLocation,
	};
}

const OfficeRoutes = connect<IStateProps, DispatchProps, {}, IApplicationState>(mapStateToProps, {
	...MapActions,
})(Component);

export default OfficeRoutes;