import * as React from 'react';

import moment from 'moment';

import { IOffice } from 'models';
import ClockIcon from '../../assets/images/clock.svg'
import 'containers/ListItemContainer.scss';
import { useIntl } from 'react-intl';

interface IProps {
	office: IOffice;
}

function transformTime(time: number): string {
	return moment(time).format('HH:mm');
}

const OfficeWorkTime: React.FC<IProps> = ({ office }) => {
	const normalBusinessHourFrom: string = transformTime(office.normalBusinessHoursFrom);
	const normalBusinessHourTo: string = transformTime(office.normalBusinessHoursTo);
	const halfDayBusinessHourFrom: string = transformTime(office.halfDayBusinessHoursFrom);
	const halfDayBusinessHourTo: string = transformTime(office.halfDayBusinessHoursTo);
	const sundayBusinessHourFrom: string = transformTime(office.sundayBusinessHoursFrom);
	const sundayBusinessHourTo: string = transformTime(office.sundayBusinessHoursTo);

	const { $t } = useIntl();
	const isOpen: boolean = React.useMemo(() => {
		const currentDateTime: Date = new Date();
		const currentDayOfWeek: number = currentDateTime.getDay();

		const currentTime: string = transformTime(currentDateTime.getTime());
		const isSaturday: boolean = currentDayOfWeek === 6;
		const isSunday: boolean = currentDayOfWeek === 0;

		if (isSunday) {
			const currentTimeIsDuringSundayHours: boolean = sundayBusinessHourFrom <= currentTime && currentTime <= sundayBusinessHourTo;
      		if (!currentTimeIsDuringSundayHours) {
        		return false;
      		}
		}
	
		if (isSaturday) {
			const currentTimeIsDuringHalfDayBusinessHours: boolean = halfDayBusinessHourFrom <= currentTime && currentTime <= halfDayBusinessHourTo;
			if (!currentTimeIsDuringHalfDayBusinessHours) {
				return false;
			}
		}

		const currentTimeIsDuringNormalBusinessHours: boolean = normalBusinessHourFrom <= currentTime && currentTime <= normalBusinessHourTo;
		if (!currentTimeIsDuringNormalBusinessHours) {
			return false;
		}
		
		return true;
	}, [halfDayBusinessHourFrom, halfDayBusinessHourTo, normalBusinessHourFrom, normalBusinessHourTo, sundayBusinessHourFrom, sundayBusinessHourTo]);

	const isAvailableOnSunday: boolean = React.useMemo(() => {
		if (!office.sundayBusinessHoursFrom || !office.sundayBusinessHoursTo)
		  return false;
		else return true;
	  }, [office]);

	return (
		<div className='office-work-hours'>    
			<img className='image' alt='clock' src={ClockIcon}/>
			<div className='office-work-hours-content'>
				<div className={`office-work-status ${isOpen ? 'open' : 'close'}`}>{isOpen ? $t({ id: 'office.time.open' }) : $t({ id: 'office.time.closed' })}</div>
				<div className='office-work-hours-item'>
					<div>{`${moment.weekdays()[1]} - ${moment.weekdays()[5]}`} <span>{normalBusinessHourFrom} - {normalBusinessHourTo}</span></div>
					<div>{moment.weekdays()[6]} <span>{halfDayBusinessHourFrom} - {halfDayBusinessHourTo}</span></div>
					{
						isAvailableOnSunday && (
							<div>{moment.weekdays()[0]} <span>{sundayBusinessHourFrom} - {sundayBusinessHourTo}</span></div>
						)
					}
				</div>
			</div>
		</div>
	)
};

export default OfficeWorkTime;
