import { Action, AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { ILocation, ILocationAddress, IRoute, Optional } from 'contracts';
import { IOffice } from 'models';
import { getMapsService, getPermissionsService } from 'services';
import { ILocationChangedListener, IMapsService, IPermissionsService } from 'services/contracts';
import { IApplicationState } from 'store/contracts';
import { Store } from 'store/store';

export interface IMapState {
	zoom: number;
    location?: ILocation;
    searchedLocation?: ILocation;
	userLocation?: ILocation;
	currentRoute?: IRoute;
}

export interface IMapActions {
	setMapLocation: (location?: ILocation) => void;
	setMapZoom: (zoom: number) => void;
	setSearchedLocation: (location?: ILocation) => void;
	setCurrentRoute: (route?: IRoute) => void;
	setUserLocation: (location?: ILocation) => void;
	requestUserLocation: () => void;
	hasLocationPermission: () => Promise<boolean>;
	getUserLocation: () => Promise<Optional<ILocation>>;
	getLocationAddress: (location: ILocation) => Promise<Optional<ILocationAddress>>;
	getAddressLocation: (address: string) => Promise<Optional<ILocation>>;
	centerOffice: (office: IOffice, isTablet: boolean) => Promise<void>;
}

const initialState: IMapState = {
	zoom: 15,
};

enum ActionTypes {
	SET_MAP_LOCATION = 'SET_MAP_LOCATION',
	SET_SEARCHED_LOCATION = 'SET_SEARCHED_LOCATION',
	SET_DIRECTIONS_STEPS = 'SET_DIRECTIONS_STEPS',
	SET_USER_LOCATION = 'SET_USER_LOCATION',
	SET_MAP_ZOOM = 'SET_MAP_ZOOM',
}

interface ActionSetMapLocation extends Action<ActionTypes> {
	type: ActionTypes.SET_MAP_LOCATION,
	data?: ILocation;
}

interface ActionSetSearchedLocation extends Action<ActionTypes> {
	type: ActionTypes.SET_SEARCHED_LOCATION,
	data?: ILocation;
}

interface ActionSetCurrentRoute extends Action<ActionTypes> {
	type: ActionTypes.SET_DIRECTIONS_STEPS,
	data?: IRoute;
}

interface ActionSetUserLocation extends Action<ActionTypes> {
	type: ActionTypes.SET_USER_LOCATION,
	data?: ILocation;
}

interface ActionSetMapZoom extends Action<ActionTypes> {
	type: ActionTypes.SET_MAP_ZOOM,
	data: number;
}

type AllActions = ActionSetMapLocation
| ActionSetSearchedLocation 
| ActionSetCurrentRoute 
| ActionSetUserLocation
| ActionSetMapZoom;

class MapSlice implements ILocationChangedListener {
	constructor(
		private readonly permissionsService: IPermissionsService,
		private readonly mapsService: IMapsService,
	) {
		this.permissionsService.addLocationChangeListener(this);
	}
	locationChanged(location: ILocation) {
		Store.dispatch(this.setUserLocation(location));
	};

	reducer(state: IMapState = initialState, action: AllActions): IMapState {
		switch(action.type) {
			case ActionTypes.SET_MAP_LOCATION:
				return { ...state, location: action.data };
			case ActionTypes.SET_SEARCHED_LOCATION:
				return { ...state, searchedLocation: action.data, currentRoute: undefined };
			case ActionTypes.SET_DIRECTIONS_STEPS:
				return { ...state, currentRoute: action.data };
			case ActionTypes.SET_USER_LOCATION:
				return { ...state, userLocation: action.data };
			case ActionTypes.SET_MAP_ZOOM:
				return { ...state, zoom: action.data };
			default:
				return state;
		}
	}

	setMapLocation(location?: ILocation): ActionSetMapLocation {
		return {
			type: ActionTypes.SET_MAP_LOCATION,
			data: location,
		};
	}

	setSearchedLocation(location?: ILocation): ActionSetSearchedLocation {
		return {
			type: ActionTypes.SET_SEARCHED_LOCATION,
			data: location,
		};
	}

	setCurrentRoute(route?: IRoute): ActionSetCurrentRoute {
		return {
			type: ActionTypes.SET_DIRECTIONS_STEPS,
			data: route,
		};
	}

	setUserLocation(location?: ILocation): ActionSetUserLocation {
		return {
			type: ActionTypes.SET_USER_LOCATION,
			data: location,
		};
	}

	setMapZoom(zoom: number): ActionSetMapZoom {
		return {
			type: ActionTypes.SET_MAP_ZOOM,
			data: zoom,
		};
	}

	hasLocationPermission(): ThunkAction<Promise<boolean>, IApplicationState, unknown, AnyAction> {
		return () => {
			return this.permissionsService.requestLocationPermission();
		};
	}

	getUserLocation(): ThunkAction<Promise<Optional<ILocation>>, IApplicationState, unknown, AnyAction> {
		return () => {
			return this.permissionsService.getCurrentLocation();
		};
	}

	getLocationAddress(location: ILocation): ThunkAction<Promise<Optional<ILocationAddress>>, IApplicationState, unknown, AnyAction> {
		return () => {
			return this.mapsService.getLocationAddress(location);
		}
	}

	getAddressLocation(address: string): ThunkAction<Promise<Optional<ILocation>>, IApplicationState, unknown, AnyAction> {
		return () => {
			return this.mapsService.getAddressLocation(address);
		}
	}

	requestUserLocation(): ThunkAction<Promise<void>, IApplicationState, unknown, AnyAction> {
		return async dispatch => {
			const hasLocationPermission = await this.permissionsService.requestLocationPermission();
            if (hasLocationPermission) {
                const location = await this.permissionsService.getCurrentLocation();
                dispatch(this.setUserLocation(location));
            }
		};
	}

	centerOffice(office: IOffice, isTablet: boolean): ThunkAction<Promise<void>, IApplicationState, unknown, AnyAction> {
		return (dispatch, getState) => {
			if (isTablet && getState().office.selectedOffice === undefined) {
				dispatch(this.setMapLocation(office.address.location));
			}

			return Promise.resolve();
		};
	}
}

const permissionsService = getPermissionsService();
const mapsService = getMapsService();

export const MapState = new MapSlice(permissionsService, mapsService);
export const MapActions: IMapActions = {
	setMapLocation: (location?: ILocation) => MapState.setMapLocation(location),
	setSearchedLocation: (location?: ILocation) => MapState.setSearchedLocation(location),
	setCurrentRoute: (route?: IRoute) => MapState.setCurrentRoute(route),
	requestUserLocation: () => MapState.requestUserLocation(),
	setUserLocation: (location?: ILocation) => MapState.setUserLocation(location),
	hasLocationPermission: () => MapState.hasLocationPermission(),
	getUserLocation: () => MapState.getUserLocation(),
	getLocationAddress: (location: ILocation) => MapState.getLocationAddress(location),
	getAddressLocation: (address: string) => MapState.getAddressLocation(address),
	centerOffice: (office: IOffice, isTablet: boolean) => MapState.centerOffice(office, isTablet),
	setMapZoom: (zoom: number) => MapState.setMapZoom(zoom),
} as any;
