import Axios from 'axios';
import { IHttpService } from "services/contracts";

const SSL_ERROR_TEXT = 'ERR_SSL_PROTOCOL_ERROR';
const RETRY_COUNT = 3;
const RETRY_INTERVAL = 2000;

function timePeriod(milliseconds: number): Promise<void> {
    return new Promise(resolve => {
        setTimeout(() => resolve(), milliseconds);
    });
}

async function sslRetry<T>(callback: () => Promise<T>, retryCount: number): Promise<any> {
    try {
        const result = await callback();
        return result;
    } catch (error: any) {
        if (retryCount === 0) {
            throw error;
        }

        const errorText = JSON.stringify(error);
        const secondErrorText = error?.toString();
        console.log(secondErrorText);
        if (errorText.includes(SSL_ERROR_TEXT) || secondErrorText?.includes(SSL_ERROR_TEXT)) {
            await timePeriod(RETRY_INTERVAL);
            return await sslRetry(callback, retryCount - 1);
        }
    }
}

class HttpService implements IHttpService {
    async post<T>(url: string, arg: any): Promise<T> {
        return sslRetry(async () => {
            const responsePost = await Axios.post<T>(url, arg);
            return responsePost.data;
        }, RETRY_COUNT);
        
    }

    async get<T>(url: string): Promise<T> {
        return sslRetry(async () => {
            const response = await Axios.get<T>(url);
            return response.data;
        }, RETRY_COUNT);
    }
}

export function createHttpService() {
    return new HttpService();
}
