import * as React from 'react';
import { OfficeType, officeTypeEnumToString } from 'models';
import { Optional } from 'contracts';
import { MapFilterButton } from './MapFilterButton';

import './MapFilterContent.scss';
import { useIntl } from 'react-intl';

interface IProps {
    isVisible: boolean;
    selectedType?: OfficeType;
    onFilter?: (selectedOfficeType?: OfficeType) => void;
    removedFilters?: string[];
}

const allOfficeTypes: Optional<OfficeType>[] = [
    OfficeType.Office,
    OfficeType.SelfService,
    OfficeType.MobileOffice,
    OfficeType.Drive,
];

const MapFilterContent: React.FC<IProps> = ({ isVisible, selectedType, onFilter, removedFilters }) => {
    const { $t } = useIntl();
    const onSelectedTypeChanged = React.useCallback((type?: OfficeType) => {
        onFilter?.(type);
    }, [onFilter]);

    const getOfficeTypes = React.useCallback(() => {
      let officeTypes: Optional<OfficeType>[] = [undefined, ...allOfficeTypes];

      if (!removedFilters || removedFilters.length === 0) {
        return officeTypes;
      }

      officeTypes = [
          undefined,
          ...allOfficeTypes
            .filter(o => !removedFilters.includes(officeTypeEnumToString(o as OfficeType)))
      ];

      return officeTypes;
    }, [removedFilters]);

    if (!isVisible) {
        return null;
    }

	return (
        <div className='map-filter-content'>
            <div className='map-filter-head'>
                <h2>{$t({ id: 'office.categories' })}</h2>
            </div>
            <div className='map-filter-buttons-container'>
                {getOfficeTypes().map((type, index) => (
                    <MapFilterButton 
                        key={index} 
                        type={type} 
                        isActive={type === selectedType} 
                        onClick={onSelectedTypeChanged} />
                ))}
            </div>
        </div>         
	);
};

export default MapFilterContent;
