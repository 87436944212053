import { ILocation, Optional } from "contracts";
import { ILocationChangedListener, IPermissionsService } from "services/contracts";

class PermissionsService implements IPermissionsService {
    private location: Optional<ILocation>;

    locationListeners: ILocationChangedListener[] = [];

    addLocationChangeListener(listener: ILocationChangedListener) {
        this.locationListeners.push(listener);
    };

    async requestLocationPermission(): Promise<boolean> {
        if (!navigator?.permissions) {
            const location = await this.getCurrentLocation();
            return location !== undefined;
        }

        const result = await navigator.permissions.query({ name: 'geolocation' });
        switch (result.state) {
            case 'granted':
                return true;
            case 'prompt':
                const location = await this.getCurrentLocation();
                return location !== undefined;
            default: // 'denied'
                return false;

        }
    }

    getCurrentLocation(): Promise<Optional<ILocation>> {
        if (this.location) {
            return Promise.resolve(this.location);
        }

        return new Promise<Optional<ILocation>>((resolve) => {
            navigator.geolocation.getCurrentPosition(position => {
                this.location = positionToLocation(position);
                resolve(this.location);
            }, () => {
                resolve(undefined);
            });
        });
    }
}

function positionToLocation(position: GeolocationPosition): ILocation {
    return {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
    };
}

const service = new PermissionsService();
export function getPermissionsService() {
    return service;
}
