import * as React from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import { LoadingIndicator } from '@econt/design-components';
import { Keys } from 'config';
import { IBoundsInfo, ILocation, IRoute, RouteType } from 'contracts';

import { toLatLng } from './utils';

import "./Map.scss";

interface IGoogleMapsProps {
    zoom: number;
    center: ILocation;
    currentCenter?: ILocation;
    boundsInfo?: IBoundsInfo;
    currentRoute?: IRoute;
    children: React.ReactNode[];
    onZoomChanged?: (zoom: number) => void;
}

interface IProps {
    lang: string;
    zoom: number;
    initialLocation: ILocation;
    currentLocation?: ILocation;
    bounds?: IBoundsInfo;
    children: React.ReactNode[];
    currentRoute?: IRoute;
    centerChanged?: (center: ILocation) => void;
    onZoomChanged?: (zoom: number) => void;
};

const lineSymbol = {
    path: 'M 0,-0.05 0,0.05',
    strokeOpacity: 1,
    scale: 7
};

function getLineOptions(route: IRoute): google.maps.PolylineOptions {
    if (route.type === RouteType.Driving) {
        return {
            geodesic: true,
            strokeColor: '#4877CE',
            strokeOpacity: 1,
            strokeWeight: 4,
            icons: [],
        };
    }

    return {
        geodesic: true,
        strokeColor: '#4877CE',
        strokeOpacity: 0,
        strokeWeight: 0,
        icons: [{ icon: lineSymbol, offset: '0', repeat: '20px' }],
    };
}

const GoogleMaps: React.FC<IGoogleMapsProps> = ({
    center,
    currentCenter,
    zoom,
    children,
    currentRoute,
    boundsInfo,
    onZoomChanged,
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();
    const [line, setLine] = React.useState<google.maps.Polyline>();

    React.useEffect(() => {
        if (ref.current && !map) {
            const newMap = new google.maps.Map(ref.current, {
                center: toLatLng(center),
                gestureHandling: 'greedy',
                clickableIcons: false,
                zoom,
                disableDefaultUI: true,
                streetViewControl: false,
                zoomControl: false,
                styles,
            });
            const zoomChanged = () => {
                const zoom = newMap.getZoom();
                if (!onZoomChanged || !zoom) {
                    return;
                }

                onZoomChanged(zoom)
            };
            newMap.addListener('zoom_changed', zoomChanged as any);
            setMap(newMap);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, map]);

    React.useEffect(() => {
        if (!map || !boundsInfo || boundsInfo.bounds.length === 0) {
            return;
        }

        const bounds = new google.maps.LatLngBounds();
        boundsInfo.bounds.forEach(l => bounds.extend(toLatLng(l)));
        map.fitBounds(bounds, boundsInfo.padding);

    }, [boundsInfo, map]);

    React.useEffect(() => {
        if (!map || !currentCenter) {
            return;
        }
        map.setCenter(toLatLng(currentCenter));
    }, [currentCenter, map]);

    React.useEffect(() => {
        if (!map) {
            return;
        }

        map.setZoom(zoom);
    }, [map, zoom]);

    React.useEffect(() => {
        if (!map) {
            return;
        }

        if (line) {
            line.setMap(null);
        }

        if (currentRoute) {
            const newLine = new google.maps.Polyline();
            newLine.setMap(map);
            setLine(newLine);
            newLine.setOptions(getLineOptions(currentRoute));
            newLine.setPath(currentRoute.steps.map(toLatLng));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRoute, map]);

    return (
        <>
            <div className='ecd-map' ref={ref} id="map" />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { map } as any);
                }
            })}
        </>
    );
}

const styles: google.maps.MapTypeStyle[] = [
    {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "transit",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "administrative",
        stylers: [{ visibility: "on" }],
    },
    {
        featureType: "landscape",
        stylers: [{ visibility: "on" }],
    },
    {
        featureType: "water",
        stylers: [{ visibility: "on" }],
    },
];

const render = ({ children, currentRoute, initialLocation, bounds, currentLocation, zoom, onZoomChanged }: IProps) => {
    return (status: Status) => {
        switch (status) {
            case Status.LOADING:
                return <LoadingIndicator />
            case Status.FAILURE:
                return <div>Failed to load map</div>
            case Status.SUCCESS:
                return (
                    <GoogleMaps
                        onZoomChanged={onZoomChanged}
                        currentCenter={currentLocation}
                        boundsInfo={bounds}
                        currentRoute={currentRoute}
                        center={initialLocation}
                        zoom={zoom}>
                        {children}
                    </GoogleMaps>
                );
        }
    };
}

export const Map: React.FC<IProps> = (props) => {
    return <Wrapper language={props.lang} apiKey={Keys.Maps} render={render(props)} />;
};
