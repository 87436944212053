import { ThunkAction } from 'redux-thunk';
import { Action, AnyAction } from '@reduxjs/toolkit';

import { ICity, IStreet, CityAutoComplete, getCityDescription } from 'models';
import { createEcontService } from 'services';
import { IEcontService } from 'services/contracts';
import { IApplicationState } from 'store/contracts';
import { IRepository, createRepository } from 'repository';

export interface ICityState {
	cities: ICity[];
	autoCompleteSource: CityAutoComplete[];
	selectedCity?: ICity;
	streets: IStreet[];
}

export interface ICityActions {
	fetchCities: (query: string) => Promise<ICity[]>;
	fetchStreets: (cityID: number) => Promise<IStreet[]>;
	setSelectedCity: (city?: ICity) => void;
}

const initialState: ICityState = {
	cities: [],
	autoCompleteSource: [],
	streets: [],
};

type ActionTypes = 'SET_CITIES' | 'SET_SELECTED_CITY' | 'SET_STREETS' | 'ADD_COUNTRY_CODE';
interface ActionSetCities extends Action<ActionTypes> {
	type: 'SET_CITIES',
	data: ICity[];
}
interface ActionSetSelectedCity extends Action<ActionTypes> {
	type: 'SET_SELECTED_CITY',
	data?: ICity;
}
interface ActionSetStreets extends Action<ActionTypes> {
	type: 'SET_STREETS',
	data: IStreet[];
}
interface ActionAddCountryCode extends Action<ActionTypes> {
	type: 'ADD_COUNTRY_CODE',
	data: string;
}
type AllActions = ActionSetCities | ActionSetSelectedCity | ActionSetStreets | ActionAddCountryCode;

class CitySlice {
	constructor(
		private readonly econtService: IEcontService,
		private readonly repository: IRepository,
	) { }

	reducer(state: ICityState = initialState, action: AllActions): ICityState {
		switch (action.type) {
			case 'SET_CITIES':
				const cities: ICity[] = action.data;
				return {
					...state, cities: cities, autoCompleteSource: cities.map(c => ({
						id: c.id,
						cityDescription: getCityDescription(c, 'bg'),
						cityDescriptionEn: getCityDescription(c, 'en')
					}))
				};
			case 'SET_SELECTED_CITY':
				return { ...state, selectedCity: action.data };
			case 'SET_STREETS':
				return { ...state, streets: action.data };
			default:
				return state;
		}
	}

	setCities(cities: ICity[]): ActionSetCities {
		return {
			type: 'SET_CITIES',
			data: cities,
		};
	}

	setStreets(streets: IStreet[]): ActionSetStreets {
		return {
			type: 'SET_STREETS',
			data: streets,
		};
	}

	setSelectedCity(city?: ICity): ActionSetSelectedCity {
		return {
			type: 'SET_SELECTED_CITY',
			data: city,
		};
	}

	addCountryCode(code: string): ActionAddCountryCode {
		return {
			type: 'ADD_COUNTRY_CODE',
			data: code,
		};
	}

	fetchCities(query: string): ThunkAction<Promise<ICity[]>, IApplicationState, unknown, AnyAction> {
		return async dispatch => {
			const cities = await this.econtService.fetchCities(query);
			dispatch(this.setCities(cities));
			return cities;
		}
	}

	findCities(query: string): ThunkAction<Promise<ICity[]>, IApplicationState, unknown, AnyAction> {
		return async dispatch => {
			const cities = await this.repository.findCities(query);
			dispatch(this.setCities(cities));
			return cities;
		};
	}

	fetchStreets(cityID: number): ThunkAction<Promise<IStreet[]>, IApplicationState, unknown, AnyAction> {
		return async dispatch => {
			const streets = await this.econtService.fetchStreets(cityID);
			dispatch(this.setStreets(streets));
			return streets;
		}
	}
}

export const CityState = new CitySlice(createEcontService(), createRepository());
export const CityActions: ICityActions = {
	fetchCities: (query: string) => CityState.fetchCities(query),
	fetchStreets: (cityID: number) => CityState.fetchStreets(cityID),
	setSelectedCity: (city?: ICity) => CityState.setSelectedCity(city),
} as any;
