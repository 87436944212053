import React from 'react';
import { ILocation, IPoint, Optional } from 'contracts';
import { toLatLng } from './utils';

interface IProps<T> {
    imageUrl: string;
    isSelected?: boolean;
    location: ILocation;
    anchorPoint?: IPoint;
    zIndex?: number;
    map?: google.maps.Map<Element>;
    item?: T;
    onClick?: (item?: T) => void;
}

function pointToAnchorPoint(point?: IPoint): Optional<google.maps.Point> {
    if (!point) {
        return undefined;
    }
    return new google.maps.Point(point.x, point.y);
}

export function MapMarker<T>(props: IProps<T>) {
    const { item, isSelected, imageUrl, zIndex, map, location, anchorPoint, onClick } = props;
    const [marker, setMarker] = React.useState<google.maps.Marker>();
    React.useEffect(() => {
        if (!marker) {
            const marker = new google.maps.Marker();
            marker.addListener('click', () => onClick?.(item));
            setMarker(marker);
        }
  
        return () => {
            if (marker) {
                marker.setMap(null);
                setMarker(undefined);
            }
        };
    }, [item, marker, onClick]);
  
    React.useEffect(() => {
        if (marker) {
            const size = isSelected ? 65 : 50;
            const scaledSize = new google.maps.Size(size, size);
            marker.setOptions({
                zIndex,
                icon: { scaledSize, url: imageUrl, anchor: pointToAnchorPoint(anchorPoint) },
                position: toLatLng(location),
                map,
            });
        }
    }, [marker, imageUrl, location, map, anchorPoint, zIndex, isSelected]);
  
    return null;
};

