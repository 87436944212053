import * as React from 'react';
import { connect } from 'react-redux';
import { EmptyOfficesView } from '@econt/design-components';
import { ICity, IOffice, OfficeType } from 'models';
import { IApplicationState } from 'store/contracts';
import { sortByProximityToLocation } from 'services/utils';
import { IOfficeActions, OfficeActions } from 'store/slices/offices';
import { ILocation, IOfficeLocatorInput } from 'contracts';
import { ListItemContainer } from 'containers';

import './OfficeListContainer.scss';

interface IStateProps {
    offices: IOffice[];
    cities: ICity[];
    selectedOffice?: IOffice;
    lastSelectedOffice?: IOffice;
    selectedCity?: ICity;
    searchedLocation?: ILocation;
    visibleOfficesType?: OfficeType;
    queryParams?: IOfficeLocatorInput;
}

type PropsType = IStateProps & IOfficeActions;

const officeTypeFilter = (type?: OfficeType) => (o: IOffice) => o.type === type || type === undefined;

const Component: React.FC<PropsType> = ({
  offices,
  searchedLocation,
  selectedOffice,
  lastSelectedOffice,
  visibleOfficesType,
  setSelectedOffice,
  queryParams,
 }) => {   
  const [sortedOffices, setSortedOffices] = React.useState(offices.filter(officeTypeFilter(visibleOfficesType)));
  const officeListRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const filtered = offices.filter(officeTypeFilter(visibleOfficesType));
    if (searchedLocation) {
      setSortedOffices([...filtered].sort(sortByProximityToLocation(searchedLocation)));
    } else {
      setSortedOffices([...filtered]);
    }
  }, [searchedLocation, offices, visibleOfficesType]);

  const scrollToOffice = React.useCallback((office: IOffice) => {
    var officeElement = document.getElementById(`office-item-${office.id}`);
    if (!officeElement || !officeListRef.current) {
      return;
    }

    officeListRef.current.scrollTop = officeElement.offsetTop - 230;
  }, []);

  React.useEffect(() => {
    if (!selectedOffice) {
      return;
    }

    scrollToOffice(selectedOffice);
  }, [scrollToOffice, selectedOffice]);

  const onOfficeClicked = React.useCallback((office: IOffice) => {
    if (office.id === selectedOffice?.id) {
      setSelectedOffice(undefined);
    } else {
      setSelectedOffice(office);
    }
  }, [selectedOffice?.id, setSelectedOffice])

  const shouldShowEmptyOfficesView = (sortedOffices.length === 0 && !queryParams?.hideMagnifyingGlass);

  return (
      <div ref={officeListRef} className='office-list-container'>
          <div className='office-list'>
            {
              sortedOffices.length > 0 && sortedOffices.map(office => (
              <ListItemContainer
                key={office.id}
                office={office} 
                isSelected={office.id === selectedOffice?.id}
                isLastSelectedOffice={lastSelectedOffice?.id === office.id}
                onClick={onOfficeClicked}/>
              ))
            }

            {shouldShowEmptyOfficesView && <EmptyOfficesView />}
          </div>
      </div>
  );
};

function mapStateToProps(state: IApplicationState): IStateProps {
    return {
        offices: state.office.offices,
        visibleOfficesType: state.office.visibleOfficesType,
        selectedOffice: state.office.selectedOffice,
        lastSelectedOffice: state.office.lastSelectedOffice,
        cities: state.city.cities,
        selectedCity: state.city.selectedCity,
        searchedLocation: state.map.searchedLocation,
        queryParams: state.app.queryParams,
    };
}

export const OfficeListContainer = connect<IStateProps, IOfficeActions, {}, IApplicationState>(mapStateToProps, OfficeActions)(Component); 