import { Action } from '@reduxjs/toolkit';
import { IInfoMessage, IOfficeLocatorInput } from 'contracts';
import { getLang, parseQueryParams } from './utils';

export interface IAppState {
    infoMessage?: IInfoMessage;
	isPopupShown: boolean;
	queryParams: IOfficeLocatorInput;
	lang: string;
}

export interface IAppActions {
	setInfoMessage: (message?: IInfoMessage) => void;
	clearInfoMessage: () => void;
	showPopup: () => void;
	hidePopup: () => void;
}

const queryParams = parseQueryParams();
const initialState: IAppState = {
	isPopupShown: false,
	queryParams,
	lang: getLang(),
};

enum ActionTypes {
	SET_SHOW_POPUP = 'SET_SHOW_POPUP',
	SET_INFO_MESSAGE = 'SET_INFO_MESSAGE',
	CLEAR_INFO_MESSAGE = 'CLEAR_INFO_MESSAGE',
}

interface ActionSetInfoMessage extends Action<ActionTypes> {
	type: ActionTypes.SET_INFO_MESSAGE,
	data?: IInfoMessage;
}

interface ActionClearInfoMessage extends Action<ActionTypes> {
	type: ActionTypes.CLEAR_INFO_MESSAGE,
}

interface ActionSetShowPopup extends Action<ActionTypes> {
	type: ActionTypes.SET_SHOW_POPUP,
	data: boolean;
}

type AllActions = ActionSetInfoMessage | ActionClearInfoMessage | ActionSetShowPopup;

class AppSlice {
	reducer(state: IAppState = initialState, action: AllActions): IAppState {
		switch(action.type) {
			case ActionTypes.SET_INFO_MESSAGE:
				const infoMessage = action.data;
				return { ...state, infoMessage, isPopupShown: infoMessage !== undefined };
			case ActionTypes.CLEAR_INFO_MESSAGE:
				return { ...state, infoMessage: undefined, isPopupShown: false };
			case ActionTypes.SET_SHOW_POPUP:
				return { ...state, isPopupShown: action.data };
			default:
				return state;
		}
	}

	setInfoMessage(error?: IInfoMessage): ActionSetInfoMessage {
		return {
			type: ActionTypes.SET_INFO_MESSAGE,
			data: error,
		};
	}

	clearInfoMessage(): ActionClearInfoMessage {
		return {
			type: ActionTypes.CLEAR_INFO_MESSAGE,
		};
	}

	setShowPopup(data: boolean): ActionSetShowPopup {
		return {
			type: ActionTypes.SET_SHOW_POPUP,
			data,
		};
	}
}

export const AppState = new AppSlice();
export const AppActions: IAppActions = {
	setInfoMessage: (message?: IInfoMessage) => AppState.setInfoMessage(message),
    clearInfoMessage: () => AppState.clearInfoMessage(),
	showPopup: () => AppState.setShowPopup(true),
	hidePopup: () => AppState.setShowPopup(false),
} as any;
