import { url, cleanEnv } from 'envalid';

const env = cleanEnv(process.env, {
  REACT_APP_ECONT_NOMENCLATURES_URL: url({
    desc: 'This is the URI used for fetching econt offices, streets etc.',
  }),
  REACT_APP_ECONT_API_ENPOINT: url({
    desc: 'This is the endpoint for retrieving city suggestions',
  }),
});

export const nomenclaturesUrl = env.REACT_APP_ECONT_NOMENCLATURES_URL;

export const apiEnpoint = env.REACT_APP_ECONT_API_ENPOINT;