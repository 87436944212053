import * as React from 'react';

import { OfficeType } from 'models';

import IconLocation from '../../../assets/Pin_around.svg'
import IconLocationSelected from '../../../assets/Pin_around_selected.svg'
import IconOffices from '../../../assets/office_filter.svg'
import IconOfficesSelected from '../../../assets/office_filter_selected.svg'
import IconEcontomat from '../../../assets/econtomat_filter.svg'
import IconEcontomatSelected from '../../../assets/econtomat_filter_selected.svg'
import IconMobileOffice from '../../../assets/mobile_office_filter.svg'
import IconMobileOfficeSelected from '../../../assets/mobile_office_filter_selected.svg'
import IconDrive from '../../../assets/Pin_drive.svg';
import IconDriveSelected from '../../../assets/Pin_drive_selected.svg';

import './MapFilterButton.scss';
import { Button } from '@progress/kendo-react-buttons';
import { useIntl } from 'react-intl';

interface IProps {
    isActive: boolean;
    type?: OfficeType;
    onClick?: (type?: OfficeType) => void;
}

const allOffices = 'office.type.all',
    office = 'office.type.office',
    econtomat = 'office.type.econtomat',
    mobileOffice = 'office.type.mobile',
    drive = 'office.type.drive';

interface ButtonInfo {
    icon: string;
    selectedIcon: string;
    text: string;
}

function getIcons(type?: OfficeType): ButtonInfo {
    switch (type) {
        case OfficeType.Office:
            return { icon: IconOffices, selectedIcon: IconOfficesSelected, text: office };
        case OfficeType.MobileOffice:
            return { icon: IconMobileOffice, selectedIcon: IconMobileOfficeSelected, text: mobileOffice };
        case OfficeType.SelfService:
            return { icon: IconEcontomat, selectedIcon: IconEcontomatSelected, text: econtomat };
        case OfficeType.Drive:
            return { icon: IconDrive, selectedIcon: IconDriveSelected, text: drive };
        default:
            return { icon: IconLocation, selectedIcon: IconLocationSelected, text: allOffices };
    }
}

export const MapFilterButton: React.FC<IProps> = ({
    type,
    isActive,
    onClick,
}) => {
    const { $t } = useIntl();
    const { icon, selectedIcon, text } = getIcons(type);
    const translatedText = $t({ id: text });
    return (
        <Button onClick={() => onClick?.(type)} className={`map-filter-button ${isActive ? 'active' : 'disable'}`} data-test-id={`map-filter-button-${text}`}>
            <img alt={translatedText} src={isActive ? selectedIcon : icon}></img>
            {translatedText}
        </Button>
    );
};