import * as React from 'react';
import 'components/App.scss';
import '@progress/kendo-theme-default/dist/all.css';
import '@econt/design-components/dist/assets/css/styles.css';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Store } from 'store';
import { Translations } from 'localization';
import { MainContainer } from 'containers';
import { getLang } from 'store/slices/utils';
import moment from 'moment';
import 'moment/locale/bg';

const usersLocale = getLang();
moment.locale(usersLocale);

function App() {
	const [locale] = React.useState(usersLocale);

	return (
		<IntlProvider locale={usersLocale} messages={Translations[locale]}>
			<Provider store={Store}>
				<div className='content-container'>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<MainContainer />} />
						</Routes>
					</BrowserRouter>
				</div>
			</Provider>
		</IntlProvider>
	);
}

export default App;
