import { latLngToLocation, toLatLng, toLocation } from "components/map/utils";
import { IHttpService, IMapsService } from "./contracts";
import { createHttpService } from "./HttpService";
import { Keys } from 'config';
import { IDistance } from "models";
import { ILocation, ILocationAddress, Optional, RouteType } from "contracts";

interface GeocodeResponse {
    results: google.maps.GeocoderResult[];
    status: google.maps.GeocoderStatus;
}

const FIVE_SECONDS = 5000;

class MapsService implements IMapsService {
    constructor(private readonly httpService: IHttpService) { }
    async getAddressLocation(address: string): Promise<ILocation | undefined> {
        const url = encodeURI(`https://maps.googleapis.com/maps/api/geocode/json?address=${address.trim()}&key=${Keys.Maps}`);
        const response = await this.httpService.get(url) as GeocodeResponse;
        if (response.status === google.maps.GeocoderStatus.OK) {
            const result = response.results[0];
            return toLocation(result.geometry.location as unknown as google.maps.LatLngLiteral);
        }
    }

    async getLocationAddress(location: ILocation): Promise<Optional<ILocationAddress>> {
        const geocoder = new google.maps.Geocoder();
        const latLng = toLatLng(location);

        return new Promise((resolve) => {
            geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    resolve({
                        addressText: results[0].address_components.filter(c => !c.types.includes('plus_code')).map(c => c.short_name).join(', '),
                        streetNum: results[0].address_components.find(c => c.types.includes('street_number'))?.short_name,
                        streetName: results[0].address_components.find(c => c.types.includes('route'))?.short_name,
                        cityNames: results[0].address_components.filter(c => c.types.includes('locality')).map(c => c.short_name),
                    });
                } else {
                    resolve(undefined);
                }
            });
        });
    }

    async calculateTravelDuration(from: ILocation, to: ILocation, type: RouteType): Promise<IDistance> {
        const request: google.maps.DirectionsRequest = {
            origin: toLatLng(from),
            destination: toLatLng(to),
            travelMode: type === RouteType.Driving ? google.maps.TravelMode.DRIVING : google.maps.TravelMode.WALKING,
        };
        return new Promise<IDistance>((resolve, reject) => {
            const DirectionsService = new google.maps.DirectionsService();
            DirectionsService.route(request, (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    const leg = result.routes[0].legs[0];
                    const distanceInfo: IDistance = {
                        distance: leg.distance.value,
                        duration: leg.duration.value,
                        steps: leg.steps.flatMap(step => step.path.flatMap(latLng => latLngToLocation(latLng))),
                    };
                    resolve(distanceInfo);
                } else if (status === google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
                    setTimeout(async () => {
                        const result = await this.calculateTravelDuration(from, to, type);
                        resolve(result);
                    }, FIVE_SECONDS);
                } else {
                    reject(status);
                }
            })
        })
    }
}

let service: MapsService;
export function getMapsService() {
    if (!service) {
        service = new MapsService(createHttpService());
    }
    return service;
}
