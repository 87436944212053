import { openDB, DBSchema } from 'idb';
import { ICity } from 'models';
import { IRepository } from 'repository/contracts';

interface CitiesDB extends DBSchema {
    cities: {
        value: ICity;
        key: string;
        indexes: { 'composite-name': string };
    };
}

// This is not being used at the moment but we're keeping it as proof of concept.
class Repository implements IRepository {
    async findCities(query: string): Promise<ICity[]> {
        const db = await openDB<CitiesDB>('cities');
        const transaction = db.transaction('cities', 'readonly')
        const objectStore = transaction.objectStore('cities');
        const cities = await objectStore.index('composite-name').getAll(query);
        await transaction.done;
        return cities;
    }

    async saveCities(cities: ICity[]): Promise<void> {
        const db = await openDB<CitiesDB>('cities', 1, {
            upgrade(db) {
                const citiesStore = db.createObjectStore('cities', {
                    keyPath: 'id',
                });
        
                citiesStore.createIndex('composite-name', 'compositeName', { multiEntry: true });
            },
        });
        const transaction = db.transaction('cities', 'readwrite')
        const store = transaction.objectStore('cities');
        cities.forEach(c => store.put(c));
        await transaction.done;
    }
}

export function createRepository(): IRepository {
    return new Repository();
}
