import { ILocation } from "contracts";

export function toLatLng(loc: { latitude: number, longitude: number }): google.maps.LatLngLiteral {
    return {
        lat: loc.latitude,
        lng: loc.longitude,
    };
}

export function toLocation(latLng: google.maps.LatLngLiteral): ILocation {
    return {
        latitude: latLng.lat,
        longitude: latLng.lng,
    };
}

export function latLngToLocation(latLng: google.maps.LatLng): ILocation {
    return {
        latitude: latLng.lat(),
        longitude: latLng.lng(),
    };
}
