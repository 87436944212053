import { Button } from '@progress/kendo-react-buttons';
import * as React from 'react';
import 'containers/ListItemContainer.scss';
import { useIntl } from 'react-intl';

interface ItemProps {
    onClick?: () => void;
}

const OfficeExportButton: React.FC<ItemProps> = ({ onClick }) => {
    const { $t } = useIntl();
	return (     
        <Button
            onClick={onClick}
			className='office-export-button btn btn-primary-solid'
			rounded="full">{$t({ id: 'office.export' })}</Button>
    )
};

export default OfficeExportButton;
