import { ILocation, Optional } from "contracts";
import { SupportedLanguages } from "localization";

export enum SearchableType {
    Street,
    Office,
    City,
}

export interface ISearchable {
    searchableType: SearchableType;
    searchField: string;
}

export enum OfficeType {
    Office,
    MobileOffice,
    SelfService,
    Drive,
}

export interface IOffice extends ISearchable {
    searchableType: SearchableType.Office;
    id: string;
    email: string;
    name: string;
    nameEn: string;
    phone: string;
    code: string;
    type: OfficeType;
    address: IAddress;
    distance?: number;
    normalBusinessHoursFrom: number;
    normalBusinessHoursTo: number;
    halfDayBusinessHoursFrom: number;
    halfDayBusinessHoursTo: number;
    sundayBusinessHoursFrom: number;
    sundayBusinessHoursTo: number;
}

export interface IDistance {
    distance: number;
    duration: number;
    steps: ILocation[];
}

export interface IAddress {
    city: ICity;
    fullAddress: string;
    fullAddressEn: string;
    location: ILocation;
    quarter: string;
    street: string;
    streetNumber: string;
}

export type CityAutoComplete = {
    id: number;
    cityDescription: string;
    cityDescriptionEn: string;
}

export interface ICity extends ISearchable {
    id: number;
    name: string;
    nameEn: string;
    postCode: string;
    searchableType: SearchableType.City,
    country: string;
    countryEn: string;
    region: string;
    regionEn: string;
    zoneId: number | null;
    zoneName: string | null;
    zoneNameEn: string | null;
}

export interface IStreet extends ISearchable {
    searchableType: SearchableType.Street;
    name: string;
    nameEn: string;
}

export interface ICountry {
    name: string;
    nameEn: string;
    code: string;
}

export interface IOfficeDto {
    id: string;
    currency: string;
    emails: string[];
    hubName: string;
    hubNameEn: string;
    normalBusinessHoursFrom: number;
    normalBusinessHoursTo: number;
    name: string;
    code: string;
    nameEn: string;
    phones: string[];
    shipmentTypes: string[];
    isMPS: boolean;
    isAPS: boolean;
    isDrive: boolean;
    address: IAddressDto;
    halfDayBusinessHoursFrom: number;
    halfDayBusinessHoursTo: number;
    sundayBusinessHoursFrom: number;
    sundayBusinessHoursTo: number;
}

export interface IAddressDto {
    fullAddress: string;
    fullAddressEn: string;
    location: ILocation;
    city: ICityDto;
    quarter: string;
    street: string;
    num: string;
}

export interface ICityDto {
    id: number;
    name: string;
    nameEn: string;
    postCode: string;
    region: string;
    regionEn: string;
    country: string;
    countryEn: string;
    zoneId: number | null;
    zoneName: string | null;
    zoneNameEn: string | null;
}

export interface ICountryDto {
    code2: string;
    code3: string;
    name: string;
    nameEn: string;
    isEU: boolean;
}

export interface IOfficeResponseDto {
    offices: IOfficeDto[];
}

export interface IStreetResponseDto {
    streets: IStreet[];
}

export interface IEcontAutoSuggestPostPayload {
    jsonrpc: string;
    method: string;
    params: Record<string, any>;
}

export interface IEcontAutoSuggestResult {
    cities: ICityDto[] | null;
}

export interface ICityResponseDto {
    jsonrpc: string;
    id: string;
    result: IEcontAutoSuggestResult;
}

export interface ICountryResponseDto {
    countries: ICountryDto[];
}

export type FetchOfficeParams = {
    cityID: number;
    showLC?: boolean;
    showCargoReceptions?: boolean;
}

// function tokenize(text: string): string[] {
//     const words = text.split(' ');
//     return words.flatMap(word => {
//         const tokens: string[] = [];
//         for (let i = 1; i <= word.length; i++) {
//             const token = word.substring(0, i);
//             if (token === '' || token === ' ') {
//                 continue;
//             }
//             tokens.push(token.toLowerCase());
//         }

//         return tokens;
//     });
// }

export function dtoToCity(dto: ICityDto): ICity {
    return {
        id: dto.id,
        name: dto.name,
        nameEn: dto.nameEn,
        postCode: dto.postCode,
        searchField: `${dto.name} ${dto.nameEn}`,
        searchableType: SearchableType.City,
        country: dto.country,
        countryEn: dto.countryEn,
        region: dto.region,
        regionEn: dto.regionEn,
        zoneId: dto.zoneId,
        zoneName: dto.zoneName,
        zoneNameEn: dto.zoneNameEn,
    };
}

export function dtoToCountry(dto?: ICountryDto): Optional<ICountry> {
    if (!dto) {
        return undefined;
    }

    return {
        name: dto.name,
        nameEn: dto.nameEn,
        code: dto.code2,
    };
}

function dtoToAddress(dto: IAddressDto): IAddress {
    return {
        city: dtoToCity(dto.city),
        fullAddress: dto.fullAddress,
        fullAddressEn: dto.fullAddressEn,
        location: dto.location,
        quarter: dto.quarter,
        street: dto.street,
        streetNumber: dto.num,
    }
}

export function dtoToOffice(dto: IOfficeDto): IOffice {
    let type: OfficeType;
    if (dto.isAPS) {
        type = OfficeType.SelfService
    } else if (dto.isMPS) {
        type = OfficeType.MobileOffice;
    } else if (dto.isDrive) {
        type = OfficeType.Drive;
    } else {
        type = OfficeType.Office;
    }

    return {
        id: dto.id,
        searchableType: SearchableType.Office,
        email: dto.emails[0],
        name: dto.name,
        nameEn: dto.nameEn,
        searchField: `${dto.name} ${dto.nameEn}`.toLowerCase(),
        phone: dto.phones[0],
        code: dto.code,
        type,
        address: dtoToAddress(dto.address),
        normalBusinessHoursFrom: dto.normalBusinessHoursFrom,
        normalBusinessHoursTo: dto.normalBusinessHoursTo,
        halfDayBusinessHoursFrom: dto.halfDayBusinessHoursFrom,
        halfDayBusinessHoursTo: dto.halfDayBusinessHoursTo,
        sundayBusinessHoursFrom: dto.sundayBusinessHoursFrom,
        sundayBusinessHoursTo: dto.sundayBusinessHoursTo,
    };
}

export function getEcontItemName(item: IOffice | IStreet | ICity | ICountry | undefined, lang: string): string {
    if (!item) {
        return '';
    }

    if (lang === SupportedLanguages.Bulgarian) {
        return item.name;
    }

    return item.nameEn;
}

export function getCountryOfCity(city: ICity | undefined, lang: string): string {
    if (!city) {
        return '';
    }
    return (lang === SupportedLanguages.Bulgarian ? city.country : city.countryEn)
}


export function getRegionOfCity(city: ICity | undefined, lang: string): string {
    if (!city) {
        return '';
    }
    return (lang === SupportedLanguages.Bulgarian ? city.region : city.regionEn)
}

export function getEcontItemFieldCities(lang: string): string {
    return (lang === SupportedLanguages.Bulgarian ? 'cityDescription' : 'cityDescriptionEn');
}

export function getEcontItemField(lang: string): string {
    return (lang === SupportedLanguages.Bulgarian ? 'name' : 'nameEn');
}


export function getCityDescription(city: ICity, lang: string): string {
    if (!city) {
        return '';
    }
    return (city.name === city.region ? `${getEcontItemName(city, lang)}` : `${getEcontItemName(city, lang)}, ${getRegionOfCity(city, lang)}`)
}

export function getEcontFullAddresName(item: IAddress | undefined, lang: string): string {
    if (!item) {
        return '';
    }

    if (lang === SupportedLanguages.Bulgarian) {
        return item.fullAddress;
    }

    return item.fullAddressEn;
}

export function officeTypeEnumToString(officeType: OfficeType): string {
  switch (officeType) {
    case OfficeType.MobileOffice:
        return 'mobileOffice';
    case OfficeType.Office:
        return 'office';
    case OfficeType.SelfService:
        return 'ekontomat';
    case OfficeType.Drive:
        return 'drive';
    default:
        throw new Error(`Unknown office type ${officeType}`);
} 
}

export function officeTypeStringToEnum(officeType: string): OfficeType {
  switch (officeType) {
    case 'mobileOffice':
      return OfficeType.MobileOffice;
    case 'office':
      return OfficeType.Office;
    case 'ekontomat':
      return OfficeType.SelfService;
    case 'drive':
      return OfficeType.Drive;
    default:
      throw new Error(`Unknown office type ${officeType}`);
  }
}

export const SOFIA_CITY: ICity = {
	id: 41,
	name: 'София',
	nameEn: 'Sofia',
	postCode: '1000',
	searchField: `sofia софия`,
	searchableType: SearchableType.City,
	country: 'България',
	countryEn: 'Bulgaria',
	region: 'София',
	regionEn: 'Sofia',
    zoneId: 1,
    zoneName:'Зона А',
    zoneNameEn: 'Зона А',
};