import * as React from 'react';
import { connect } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useIntl } from 'react-intl';

import { ICity, IOffice, OfficeType, officeTypeEnumToString, SOFIA_CITY } from 'models';
import {
    Map,
    MapMarker,
    DrawerContent,
    MapFilter,
    OfficePopup,
} from 'components';
import { LoadingIndicator, Spinner, MessageView, Drawer } from '@econt/design-components';
import { useNetworkRequest, useResponsive } from 'hooks';
import { IBoundsInfo, IInfoMessage, ILocation, ILocationAddress, IOfficeLocatorInput, IPadding, IRoute, Optional } from 'contracts';
import { DrawerHeaderContainer, InputContainer, OfficeListContainer } from 'containers';

import { IOfficeActions, OfficeActions } from 'store/slices/offices';
import { IApplicationState } from 'store/contracts';

import { getOfficeMarkerIconState } from 'containers/utils'
import MapPin from 'assets/images/pin.svg';
import MapPinDecoration from 'assets/images/location-circle.svg';

import { CityActions, ICityActions } from 'store/slices/cities';
import { AppActions, IAppActions } from 'store/slices/app';
import { IMapActions, MapActions } from 'store/slices/map';

import './MainContainer.scss';
import 'react-spring-bottom-sheet/dist/style.css';
import ErrorIcon from '../assets/images/error.svg';
import { ILocationSearchActions, LocationSearchActions } from 'store/slices';
import { findCity } from 'services/utils';
import { LocationIconButton } from 'components/LocationIconButton';

interface IStateProps {
    lang: string;
    offices: IOffice[];
    cities: ICity[];
    isPopupShown: boolean;
    mapLocation?: ILocation;
    searchedLocation?: ILocation;
    userLocation?: ILocation;
    selectedOffice?: IOffice;
    selectedCity?: ICity;
    currentRoute?: IRoute;
    infoMessage?: IInfoMessage;
    visibleOfficesType?: OfficeType;
    queryParams: IOfficeLocatorInput;
    mapZoom: number;
}

type DispatchProps = IOfficeActions & ICityActions & IMapActions & IAppActions & ILocationSearchActions;

type PropsType = IStateProps & DispatchProps;

function getOfficeMarkerIcon(office: IOffice, selectedOffice?: IOffice): string {
    const isSelected = office.id === selectedOffice?.id;
    return getOfficeMarkerIconState(office.type, isSelected)
}

function officeFilter(officeType?: OfficeType) {
    return (o: IOffice) => o.type === officeType || officeType === undefined;
}

const DEFAULT_LOCATION: ILocation = { latitude: 42.70, longitude: 23.33 }; // Sofia

const Component: React.FC<PropsType> = ({
    isPopupShown,
    infoMessage,
    offices,
    mapLocation,
    searchedLocation,
    userLocation,
    currentRoute,
    selectedOffice,
    selectedCity,
    visibleOfficesType,
    queryParams,
    mapZoom,
    lang,
    setInfoMessage,
    clearInfoMessage,
    setSelectedOffice,
    setMapLocation,
    setMapZoom,
    setCurrentRoute,
    requestUserLocation,
    setVisibleOfficeType,
    fetchOffices,
    setOffices,
    fetchCities,
    setSelectedCity,
    hasLocationPermission,
    getUserLocation,
    getLocationAddress,
    getAddressLocation,
    setSearchedLocation,
    centerOffice,
}) => {
    const { $t } = useIntl();
    const { isTablet, isPhoneLandscape } = useResponsive();
    const [isLoading, setIsLoading] = React.useState(true);
    const [expanded, setExpanded] = React.useState(true);
    const [isBottomSheetVisible, setIsBottomSheetVisible] = React.useState(true);
    const [mapBounds, setMapBounds] = React.useState<Optional<IBoundsInfo>>();

    const citiesRequest = useNetworkRequest<ICity | undefined>();
    const officesRequest = useNetworkRequest<IOffice[]>();
    const locationAddressRequest = useNetworkRequest<Optional<ILocationAddress>>();
    const addressLocationRequest = useNetworkRequest<Optional<ILocation>>();

    React.useEffect(() => {
        if (citiesRequest.error || officesRequest.error) {
            setInfoMessage({
                iconUrl: ErrorIcon,
                title: $t({ id: 'app.error-title' }),
                text: $t({ id: 'app.generic-error' }),
                titleClassName: 'error',
                clear: clearInfoMessage,
            });
        }
    }, [$t, clearInfoMessage, setInfoMessage, officesRequest.error, citiesRequest.error]);

    React.useEffect(() => {
        if (!queryParams.skipLocation) {
            requestUserLocation();
        }
    }, [requestUserLocation, queryParams.skipLocation]);

    const setInitialLocation = React.useCallback(async (location?: ILocation, shouldGetAddress?: boolean) => {
        if (!location) {
            return;
        }

        let addressInfo: Optional<ILocationAddress> = undefined;
        if (shouldGetAddress) {
            addressInfo = await locationAddressRequest.fetch(() => getLocationAddress(location));
        }
        setSearchedLocation({
            ...location,
            addressText: addressInfo?.addressText,
        });
        setMapLocation(location);
    }, [getLocationAddress, locationAddressRequest, setMapLocation, setSearchedLocation]);

    React.useEffect(() => {
        const callback = async () => {
            try {
                if (queryParams.location) {
                    await setInitialLocation(queryParams.location);
                    const addressInfo = await locationAddressRequest.fetch(() => getLocationAddress(queryParams.location!));
                    if (addressInfo?.cityNames) {
                        const city = await citiesRequest.fetch(() => findCity(addressInfo.cityNames, fetchCities));
                        setSelectedCity(city);
                    }
                } else if (queryParams.city) {
                    let foundCity: ICity | undefined = undefined

                    const postCode = queryParams.city.replace(/[^0-9]/g, '');
                    if(postCode) {
                        const foundCityByPostCode = await citiesRequest.fetch(() => findCity([postCode!], fetchCities));
                        const cityName = queryParams.city?.replace(/[0-9-]/g, '').toLowerCase();
                        if(foundCityByPostCode && 
                            (foundCityByPostCode.name?.toLowerCase().includes(cityName) || 
                            foundCityByPostCode.nameEn?.toLowerCase().includes(cityName) ||
                            cityName.toLowerCase().includes(foundCityByPostCode.name?.toLowerCase()) || 
                            cityName.toLowerCase().includes( foundCityByPostCode.nameEn?.toLowerCase()) ||
                            postCode.length === queryParams.city.length)
                        ) {
                            foundCity = foundCityByPostCode
                        }
                    } else {
                        foundCity = await citiesRequest.fetch(() => findCity([queryParams.city!], fetchCities));
                    }

                    if (foundCity) {
                        // Previous implementation was with english names, but the location 
                        // Вълчедръм, Монтана (Vylchedrym Montana) redirects the user to the USA
                        // const address = `${foundCity.nameEn} ${foundCity.regionEn && foundCity.regionEn} ${queryParams.address || ''}`;
                        const address = `${foundCity.name} ${foundCity.region && foundCity.region} ${queryParams.address || ''}`
                        const location = await addressLocationRequest.fetch(() => getAddressLocation(address));
                        await setInitialLocation(location, queryParams.address !== undefined);
                        setSelectedCity(foundCity);
                    } else {
                        setSelectedCity(SOFIA_CITY);
                    }
                } else {
                    const location = queryParams.skipLocation
                      ? undefined
                      : await getUserLocation();

                    if (location) {
                        const addressInfo = await locationAddressRequest.fetch(() => getLocationAddress(location));
                        const city = await citiesRequest.fetch(() => findCity(addressInfo?.cityNames, fetchCities));
                        if (city) {
                            setSelectedCity(city);
                            setSearchedLocation({
                                ...location,
                                addressText: addressInfo?.addressText,
                            });
                        }
                        setMapLocation(location);
                    } else {
                        setSelectedCity(SOFIA_CITY);
                    }
                }
            } finally {
                setIsLoading(false);
            }
        };

        setTimeout(callback, 500);
    }, [fetchCities, getAddressLocation, getLocationAddress, getUserLocation, hasLocationPermission, citiesRequest, queryParams, setInitialLocation, setMapLocation, setSearchedLocation, setSelectedCity, locationAddressRequest, addressLocationRequest, lang]);

    const toggleDrawer = React.useCallback(() => {
        setExpanded(!expanded);
    }, [expanded, setExpanded]);

    const onMapFilterChanged = React.useCallback((type?: OfficeType) => {
        if (type === visibleOfficesType) {
            setVisibleOfficeType(undefined);
        } else {
            setVisibleOfficeType(type);
        }
        setSelectedOffice(undefined);
    }, [setSelectedOffice, setVisibleOfficeType, visibleOfficesType]);

    const onMarkerClick = React.useCallback((office?: IOffice) => {
        if (!office) {
            return;
        }

        centerOffice(office, isTablet);
        setSelectedOffice(office);
    }, [centerOffice, isTablet, setSelectedOffice]);

    React.useEffect(() => {
        setCurrentRoute(undefined);
    }, [setCurrentRoute, selectedCity, selectedOffice]);

    React.useEffect(() => {
        if (!selectedOffice) {
            return;
        }

        setExpanded(true);
    }, [selectedOffice]);

    const filterOfficesByQueryParam = React.useCallback((office: IOffice) => {
      const queryFilters = queryParams.filterOut;

      if (!queryFilters) {
        return true;
      }

      return !queryFilters.includes(officeTypeEnumToString(office.type));
    }, [queryParams.filterOut]);

    React.useEffect(() => {
        if (!selectedCity) {
            setOffices([]);
            return;
        }

        (async () => {
            const offices = await officesRequest.fetch(() => fetchOffices(
                {
                    cityID: selectedCity.id,
                    showLC: queryParams.showLC,
                },
                filterOfficesByQueryParam
            ));
            if (offices) {
                setMapZoom(getMapZoom(offices.length));
            }
        })();
    }, [fetchOffices, officesRequest, selectedCity, setMapZoom, setOffices, filterOfficesByQueryParam, queryParams.showLC]);

    React.useEffect(() => {
        if (!currentRoute) {
            return;
        }

        setMapBounds({
            bounds: [currentRoute.start, currentRoute.destination],
            padding: getMapBoundsPadding(isTablet, expanded),
        });
    }, [currentRoute, expanded, isTablet]);

    const onFilterStateChange = React.useCallback((isOpen: boolean) => {
        setIsBottomSheetVisible(!isOpen);
    }, []);

    const onZoomChanged = React.useCallback((zoom: number) => {
        setMapZoom(zoom);
    }, [setMapZoom]);

    const onZoomIncrement = React.useCallback(() => {
        setMapZoom(mapZoom + 1);
    }, [mapZoom, setMapZoom]);

    const onZoomDecrement = React.useCallback(() => {
        setMapZoom(mapZoom - 1);
    }, [mapZoom, setMapZoom]);

    const markers = offices.filter(officeFilter(visibleOfficesType)).map(office =>
        <MapMarker
            item={office}
            onClick={onMarkerClick}
            key={office.id}
            location={office.address.location}
            isSelected={office.id === selectedOffice?.id}
            imageUrl={getOfficeMarkerIcon(office, selectedOffice)} 
            zIndex={1} />
    );

    const renderMap = React.useCallback(() => {
        return (
            <div className={`map-container ${((isTablet || isPhoneLandscape) && selectedOffice) ? 'compressed' : ''}`}>
                <Map initialLocation={DEFAULT_LOCATION}
                    lang={lang}
                    onZoomChanged={onZoomChanged}
                    zoom={mapZoom}
                    currentLocation={mapLocation}
                    bounds={mapBounds}
                    currentRoute={currentRoute}>
                    {markers}
                    {
                        userLocation && <MapMarker
                            zIndex={1}
                            anchorPoint={{ x: 25, y: 29 }}
                            location={userLocation}
                            imageUrl={MapPinDecoration} />
                    }
                    {
                        searchedLocation && <MapMarker
                            zIndex={2}
                            location={searchedLocation}
                            imageUrl={MapPin} />
                    }
                </Map>
            </div>
        );
    }, [currentRoute, isTablet, isPhoneLandscape, lang, mapBounds, mapLocation, mapZoom, onZoomChanged, searchedLocation, selectedOffice, userLocation, markers]);

    const renderFilterAndDrawer = () => {
        const { hideDrawer, hideFilter } = queryParams;

        if ((isTablet || isPhoneLandscape) && !isPopupShown) {
            return (
                <>
                    {!hideDrawer && <BottomSheet
                        header={(
                            <div className='edl-drawer-content-container-phone'>
                                <DrawerHeaderContainer />
                                <InputContainer />
                            </div>
                        )}
                        className={isBottomSheetVisible && !selectedOffice ? 'visible' : 'hidden'}
                        open={true}
                        skipInitialTransition
                        expandOnContentDrag={false}
                        blocking={false}
                        defaultSnap={() => 320}
                        snapPoints={({ maxHeight }) => [
                            70,
                            maxHeight * 0.5,
                            maxHeight * 0.95,
                        ]}>
                        <div className='edl-drawer-content-container-phone'>
                            <OfficeListContainer />
                        </div>
                    </BottomSheet>}
                    {!hideFilter && (
                        <MapFilter
                            selectedOfficeType={visibleOfficesType}
                            onFilterStateChange={onFilterStateChange}
                            onFilter={onMapFilterChanged}
                            removedFilters={queryParams.filterOut}
                        />
                    )}
                </>
            )
        }

        if (!isTablet) {
            const style: React.CSSProperties = {};

            if (hideDrawer) {
                style.justifyContent = 'end';
            }

            return (
                <div className='drawer-container' style={style}>
                    {!hideDrawer && (
                        <Drawer toggleDrawer={toggleDrawer} expanded={expanded}>
                            <DrawerContent />
                        </Drawer>
                    )}
                    {!hideFilter && (
                        <MapFilter
                            selectedOfficeType={visibleOfficesType}
                            onFilter={onMapFilterChanged}
                            removedFilters={queryParams.filterOut}
                        />
                    )}
                </div>
            )
        }
    };

    return (
        <div className={`main-container ${((isTablet || isPhoneLandscape) && selectedOffice) ? 'map-office' : ''}`}>
            {queryParams.currentLocationIcon && (
              <LocationIconButton
                setLoading={(value: boolean) => setIsLoading(value)}
                positionProps={queryParams.currentLocationIcon}
              />)}

            {renderMap()}
            {renderFilterAndDrawer()}

            <div className='zoom-container'>
                <Spinner onIncrement={onZoomIncrement} onDecrement={onZoomDecrement} />
            </div>

            {
                (isTablet || isPhoneLandscape) && selectedOffice &&
                <OfficePopup office={selectedOffice} />
            }
            {isPopupShown && (
                <div className='popup-container'>
                    {infoMessage && <MessageView message={infoMessage} />}
                </div>
            )}

            {isLoading && (
                <div className='loading-container'>
                    <LoadingIndicator />
                </div>
            )}
        </div>
    );
};

function mapStateToProps(state: IApplicationState): IStateProps {
    return {
        offices: state.office.offices,
        cities: state.city.cities,
        selectedOffice: state.office.selectedOffice,
        selectedCity: state.city.selectedCity,
        mapLocation: state.map.location,
        searchedLocation: state.map.searchedLocation,
        userLocation: state.map.userLocation,
        visibleOfficesType: state.office.visibleOfficesType,
        currentRoute: state.map.currentRoute,
        isPopupShown: state.app.isPopupShown,
        infoMessage: state.app.infoMessage,
        queryParams: state.app.queryParams,
        mapZoom: state.map.zoom,
        lang: state.app.lang,
    };
}

export const MainContainer = connect<IStateProps, DispatchProps, {}, IApplicationState>(mapStateToProps, {
    ...OfficeActions,
    ...CityActions,
    ...MapActions,
    ...AppActions,
    ...LocationSearchActions,
})(Component);

function getMapZoom(officeCount: number): number {
    if (officeCount < 20) {
        return 16;
    }

    return 14;
}

function getMapBoundsPadding(isTablet: boolean, expanded: boolean): IPadding {
    if (isTablet) {
        return { left: 30, top: 30, right: 30, bottom: 30 };
    }

    const left = expanded ? 415 : 50;
    return { left, top: 200, right: 50, bottom: 50 };
}